import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import CommonVar , {deletePopup} from '../CommonAsset'
import $ from 'jquery'
import 'jquery-ui-bundle';

class Links extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusElement:false,
            focusIndex:''
         }
    }
    componentDidMount(){
        
        $( "#droppable" ).droppable();

        CommonVar.getAPI('bio/get_bio' , 'get' , {}).then((resp) => {
            console.log(resp);
            if(resp.status === 200){
                this.props.dragitems({
                    items:resp.data
                })
                this.initSort()
            }
        });
     
    }

    publishBio = () => {
        CommonVar.getAPI('bio/publish_bio' , 'post' , {data : this.props.items}).then((resp) => {
            console.log(resp);
            if(resp.status === 200){
                
            }
        });
    }

    handleDelete=(itemindex)=>{
        let itemremove = this.props.items
        deletePopup('Are you sure?','Delete','swal2-delete').then((result) => {
            // loader();
            
            if(result.value){
                itemremove.splice(itemindex,1);
                this.props.dragitems({
                    items:itemremove
                })
            }
        })
    }
    handleClone=(itemindex)=>{
        console.log(itemindex)
        let items = this.props.items

        const defaulData = {
            id : 0,
            layout : 1,
            title : 'this is test default',
            link : 'http://localhost:3000/',
            images : '/assets/images/user.png'
        }
        items.splice(itemindex+1, 0, defaulData);
        console.log(items)

        this.props.dragitems({
            items:items
        })

        this.setState({
            focusElement:true,
            focusIndex:itemindex+1
        })

        setTimeout(()=>{
            this.setState({
                focusElement:false
            })
        },2000)
    }


    initSort = () => {
        $( ".sortable" ).sortable({
            handle:'.ed_sort',
            axis:'y'
        });
        $( ".sortable" ).disableSelection();
    }

    render() { 
        console.log(this.state.focusElement)
       this.initSort()
        return ( 
            <>
            <div className={this.props.items.length?"ed_links_title":"ed_none"}>
                <h6>Links</h6>
                <div> 
                    <Link to="preview"><button className="ed_btn">Preview</button></Link>
                    <button onClick={this.publishBio} className="ed_btn">Publish</button>
                </div>
            </div>
            <div className={this.props.items.length?"ed_links sortable":"ed_link_wrapper"} id="droppable">
                    {this.props.items.length?
                        this.props.items.map((item,index)=>{
                            return <div key={index} className={this.state.focusElement&&this.state.focusIndex === index?"ed_focusElement ed_link ed_box":"ed_link ed_box"} >
                                 <div className="ed_linkImg">
                                     <img src={item.images} alt="" />
                                 </div>
                                 <div className="ed_linkDetail">
                                    <h6 contentEditable="true">{item.title}</h6>
                                 </div>
                                 <span className="ed_sort"><i class="fal fa-ellipsis-v"></i><i class="fal fa-ellipsis-v"></i></span>

                                 <div className="ed_link_actions">
                                     <ul>
                                         <li style={{transitionDelay:'0s'}}><i class="far fa-cog"></i></li>
                                         <li onClick={()=>this.handleClone(index)} style={{transitionDelay:0.1+'s'}}><i class="far fa-clone"></i></li>
                                         {this.props.items.length > 1?
                                             <li onClick={()=>this.handleDelete(index)}  style={{transitionDelay:0.2+'s'}}><i class="far fa-trash-alt"></i></li>
                                         :''}
                                     </ul> 
                                 </div>
                             </div>
                        })
                    :
                        <span>Drop Here</span>
                    }
            </div>
            </>
         );
    }
}
 
export default Links;