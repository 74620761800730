import React, { Component } from 'react';
import CommonVar from '../CommonAsset'
import Profile from '../common/Profile'
import css from '../css/preview.module.css'
import $ from 'jquery'
import 'jquery-ui-bundle';

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items:[]
          }
    }
    componentDidMount(){
        
        $( "#droppable" ).droppable();

        CommonVar.getAPI('bio/get_bio' , 'get' , {}).then((resp) => {
            console.log(resp);
            if(resp.status === 200){
                this.setState({
                    items:resp.data
                })
            }
        });
     
    }

    render() { 
        return ( 
            <div className={css.ed_preview_wrapper}>
              <div className="row">
                <div className="container">
                    <div className="col-lg-12">
                        <Profile/>  
                        <div className="ed_links sortable">
                                {this.state.items.length?
                                    this.state.items.map((item,index)=>{
                                        return <a href={item.link}><div key={index} className="ed_link ed_box ">
                                            <div className="ed_linkImg">
                                                <img src={item.images} alt="" />
                                            </div>
                                            <div className="ed_linkDetail">
                                                <h6 contentEditable="true">{item.title}</h6>
                                            </div>
                                            <span className="ed_sort"><i class="fal fa-ellipsis-v"></i><i class="fal fa-ellipsis-v"></i></span>
                                        </div>
                                        </a>
                                    })
                                :
                                ''
                                }
                        </div>
                    </div>
                </div>
            </div>
            </div>
         );
    }
}
 
export default Preview;