import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
import {Switch, Route } from 'react-router-dom';
//import PrivateRoute  from 'react-private-route';
//import {isLoggedIn, component}  from 'react-private-route';

import Content from './pages/Content';
import Preview from './pages/Preview';
import Editor from './pages/Editor';
import URL from './pages/Url';
// import Test from './pages/Test';
// import Login from './Login';

class Main extends Component{
  constructor(props) { 
    super(props);
    this.state = {  }
  }   
  render() {  
        return (  

            <>
                <Switch>
                    {/* <Route path="/test" component={Test} />  */}
                    <Route path="/url" component={URL} /> 
                    <Route path="/preview" component={Preview} /> 
                    <Route path="/*" component={Editor} /> 
                    {/* <Route path="/*" component={Content} />  */}
                </Switch>  
            </>
        );  
  }  
}
 
export default Main;  