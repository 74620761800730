import React, { Component } from 'react';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>
             <div className="ed_box ed_profile_wrappper">
                <h1>Hello, Good Morning</h1>
                <div>
                    <img src="assets/images/user.png" alt="" />
                    <div>
                        <h6>Deepa</h6>
                        <p>http://localhost:3000/</p>
                    </div>
                </div>
            </div>
            </>
         );
    }
}
 
export default Profile;