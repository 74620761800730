import React, { Component } from 'react';
import Header from '../common/Header'
import Sidebar from '../common/Sidebar'
import Profile from '../common/Profile'
import Links from './Links'

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            items:[]
         }
    }

    dropElement = (newState = {}) => {
        this.setState(newState);
    }

    render() { 
        console.log(this.state.items)
        return ( 
            <>
            <Header />
            <div className="ed_main_wrapper">
                <div className="ed_left">
                    <Sidebar dragitems={this.dropElement} items={this.state.items}/>
                </div>
                <div className="ed_right">
                    <div className="row">
                        <div className="container">
                            <div className="col-lg-12">
                               <Profile/>  
                               <Links dragitems={this.dropElement} items={this.state.items} />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
         );
    }
}
 
export default Content;