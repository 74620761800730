import React, { Component } from 'react';

class URL extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <h1>fhfgh fghfg fghgfh fgggghf fgh</h1>
         );
    }
}
 
export default URL;