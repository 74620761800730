import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import $ from 'jquery'

let baseUrl = 'https://wix.node.pixelnx.com/build/';
let ApiUrl = 'https://wix.node.pixelnx.com/';


// let baseUrl = 'http://127.0.0.1:3000';
// let ApiUrl = 'http://127.0.0.1:4000/';  



localStorage.setItem('authToken' , 'MQ==');
export default { 
    baseUrl : baseUrl,
    ApiUrl : ApiUrl,
    getAPI : (targetUrl , requestMethod , sendData , type='') =>{
        sendData.token = localStorage.getItem('authToken');
        // sendData.authTokenKey = localStorage.getItem('authTokenKey');
        var detail = {
            method : requestMethod,
          };
        if (requestMethod === 'post' || requestMethod === 'delete'){
            // detail['headers']={ 'Content-Type': 'application/json' };
            if(type!=='' && (type ==='image' || type === "video" || type === "voice" || type === 'file')){
                detail['body'] = sendData;
            }else{
                //detail['headers']={'Content-Type': 'application/json' , 'Cache-Control': 'no-cache'};
				console.log(type)
				if(type === 'cors'){
					console.log(type)
                   detail['headers']={ 'Content-Type': 'application/json' , 'Accept': 'application/json', 'Cache-Control' : 'no-cache'};
                }else{
                    detail['headers']={ 'Content-Type': 'application/json' , 'Cache-Control' : 'no-cache'};
                }
                detail['body'] = JSON.stringify(sendData);
            }
            console.log(detail)
			
        }else{
            detail['headers'] = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
                // 'Cache-Control' : 'no-cache'
            };

            if(Object.keys(sendData).length){
                var str = [];
                for (var p in sendData){
                    if (sendData.hasOwnProperty(p)) {
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(sendData[p]));
                    }
                }
                //targetUrl += '?'+str.join('&');    
				if(targetUrl.indexOf('?') !== -1){
                    targetUrl += '&'+str.join('&'); 
				}else{
				targetUrl += '?'+str.join('&'); 
				}  				
            }
        }
        // console.log(targetUrl)
		var fetchFunc = fetch(ApiUrl+targetUrl ,detail).then(res2 => res2.json());
		// if(type!=='' && (type ==='image' || type === "video" || type === "voice" || type === 'file')){
        //     var fetchFunc = fetch(ApiUrl+targetUrl ,detail);
        // }else{
        //     var fetchFunc = fetch(ApiUrl+targetUrl ,detail).then(res2 => res2.json());
        // }
        return fetchFunc;
    }
}

// deletePopup('Are you sure?','Delete','swal2-delete')
export const deletePopup = (msg,buttonText,buttonClass) => {
    return(
        Swal.fire({
            text: msg,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: buttonText,
            customClass: {
              confirmButton: buttonClass,
            }
        })
    );
} 

export const customScroll = (hendler,action) => {
    //  require('malihu-custom-scrollbar-plugin')($);
    window.jQuery = $
    require("jquery-mousewheel")
    require('malihu-custom-scrollbar-plugin');
    if(action === "distroy"){
        $(hendler).mCustomScrollbar("disable",true);
    }else{
        $(hendler).mCustomScrollbar({
            theme: 'dark',
            disable:false,
            mouseWheel:{ disableOver:['select,div']}
        })
    }
}