import '../css/sidebar.css'
export const  cache = {};   


let  PixelPagesTemplate = (type , data) => {
    if(type == 'pixelpages-property'){
        return `<div class="form-group ${((typeof data.col !== 'undefined' && data.col != false)?`col-sm-${data.col} d-inline-block`:`row`)}" data-key="${data.key}" ${((typeof data.group !== 'undefined' && data.group != null)?`data-group="${data.group}"`:  ``)}>
            ${((typeof data.name !== 'undefined' && data.name != false)?`<label class="${((typeof data.inline === 'undefined' )?`col-sm-4`:``)} control-label" for="input-model">${data.name}</label>`:``)}
            <div class="${((typeof data.inline === 'undefined')?`col-sm-${((typeof data.name !== 'undefined' && data.name != false)?8:12)}`:'')} input"></div>
        </div>`;
    }else if(type == 'pixelpages-input-textinput'){
        return '<div><input name="'+data.key+'" type="text" class="form-control"/></div>';
    }else if(type == 'pixelpages-input-textareainput'){
        return '<div><textarea name="'+data.key+'" rows="3" class="form-control"/></div>';
    }else if(type == 'pixelpages-input-checkboxinput'){
        return '<div class="custom-control custom-checkbox"> <input name="'+data.key+'" class="custom-control-input" type="checkbox" id="'+data.key+'_check"> <label class="custom-control-label" for="'+data.key+'_check">'+((typeof text !=='undefined')?data.text:'')+'</label></div>';
    }else if(type == 'pixelpages-input-radioinput'){
        let optionData = data.options;
        var optionHtml = '';
        for ( let i=0; i < optionData.length; i++ ){
            optionHtml += '<label class="custom-control custom-radio '+(typeof data.inline !=='undefined' && data.inline==true)?'custom-control-inline':''+'" title="'+optionData[i].title+'"> <input name="'+data.key+'" class="custom-control-input" type="radio" value="'+optionData[i].value+'" id="'+data.key+i+'" '+((optionData[i].checked)?'checked="'+optionData[i].checked+'"':'')+'> <label class="custom-control-label" for="'+data.key+i+'">'+optionData[i].text+'</label></label>';
        }
        
        
        return '<div>'+optionHtml+'</div>';
    }else if(type == 'pixelpages-input-radiobuttoninput'){
        let radioInput = data.options;
        console.log(radioInput)
        var radioInputHtml = '';
        for ( let i=0; i < radioInput.length; i++ ){
            radioInputHtml += '<label class="btn btn-outline-primary '+((radioInput[i].checked)?'active':'')+'  '+((radioInput[i].extraclass)?radioInput[i].extraclass:'')+'" for="'+data.key+i+'" title="'+radioInput[i].title+'"> <input name="'+data.key+'" class="custom-control-input" type="radio" value="'+radioInput[i].value+'" id="'+data.key+i+'" '+((radioInput[i].checked)?'checked="'+radioInput[i].checked+'"':'')+'>'+((radioInput[i].icon)?'<i class="'+radioInput[i].icon+'"></i>':'')+'</label>';
        }
        
        return '<div class="btn-group btn-group-toggle '+((data.extraclass)?data.extraclass:'')+' clearfix" data-toggle="buttons">'+radioInputHtml+'</div>';
    }else if(type == 'pixelpages-input-toggle'){
        return '<div class="toggle"> <input type="checkbox" name="'+data.key+'" value="'+data.on+'" '+((data.off)?'data-value-off="'+data.off+'"':'')+' '+((data.on)?'data-value-on="'+data.on+'"':'')+' class="toggle-checkbox" id="'+data.key+'"> <label class="toggle-label" for="'+data.key+'"> <span class="toggle-inner"></span> <span class="toggle-switch"></span> </label> </div>';
    }else if(type == 'pixelpages-input-header'){
        return '<h6 class="header">'+data.header+'</h6>';
    }else if(type == 'pixelpages-input-select'){
        var inputSelectObj = data.options;
        
        let inputSelectHtml = '';
        for ( let i=0; i < inputSelectObj.length; i++ ){
            inputSelectHtml +=  '<option value="'+inputSelectObj[i].value+'">'+inputSelectObj[i].text+'</option>';
        }
        return '<div><select class="form-control custom-select">'+inputSelectHtml+'</select></div>';
    }else if(type == 'pixelpages-input-listinput'){
        var listInputHtml = '';
        let listInput = data.options;
        for ( let i=0; i < listInput.length; i++ ){
            listInputHtml += `<div class="col-6"><div class="input-group"><input name="${data.key}_${i}" type="text" class="form-control" value="${listInput[i].text}"/><div class="input-group-append"><button class="input-group-text btn btn-sm btn-danger"><i class="far fa-trash la-lg"></i></button></div></div><br/></div>`;
        }
        
        return `<div class="row">${listInputHtml} ${(typeof data.hide_remove==='undefined')?'<div class="col-12"><button class="btn btn-sm btn-outline-primary"><i class="far fa-trash la-lg"></i> Add new</button></div>':''}</div>`;
    }else if(type == 'pixelpages-input-grid'){
        var largeSelOpt = '';
        for ( var i = 1; i <= 12; i++ ){
            largeSelOpt += `<option value="${i}" ${((typeof data.col_lg !== 'undefined') && data.col_lg == i)?'selected':''}>${i}</option>`;
        }

        var medSelOpt = '';
        for ( var i = 1; i <= 12; i++ ){
            medSelOpt += `<option value="${i}" ${((typeof data.col_md !== 'undefined') && data.col_md == i)?'selected':''}>${i}</option>`;
        }

        var smSelOpt = '';
        for ( var i = 1; i <= 12; i++ ){
            smSelOpt += `<option value="${i}" ${((typeof data.col_sm !== 'undefined') && data.col_sm == i)?'selected':''}>${i}</option>`;
        }


        var FlexboxSelOpt = '';
        for ( var i = 1; i <= 12; i++ ){
            FlexboxSelOpt += `<option value="${i}" ${((typeof data.col !== 'undefined') && data.col == i)?'selected':''}>${i}</option>`;
        }

        var xsSelOpt = '';
        for ( var i = 1; i <= 12; i++ ){
            xsSelOpt += `<option value="${i}" ${((typeof data.col_xs !== 'undefined') && data.col_xs == i)?'selected':''}>${i}</option>`;
        }

         
        
        return `<div class="row">
                <div class="mb-1 col-12">
                    <label>Flexbox</label>
                    <select class="form-control custom-select" name="col">
                        <option value="">None</option>
                        ${FlexboxSelOpt}
                    </select>
                    <br/>
                </div>

                <div class="col-6">
                    <label>Extra small</label>
                    <select class="form-control custom-select" name="col-xs">
                        <option value="">None</option>
                        ${xsSelOpt}
                    </select>
                    <br/>
                </div>
                <div class="col-6">
                    <label>Small</label>
                    <select class="form-control custom-select" name="col-sm">
                        <option value="">None</option>
                        ${smSelOpt}
                    </select>
                    <br/>
                </div>
                
                <div class="col-6">
                    <label>Medium</label>
                    <select class="form-control custom-select" name="col-md">
                        <option value="">None</option>
                        ${medSelOpt}
                    </select>
                    <br/>
                </div>
                <div class="col-6 mb-1">
                    <label>Large</label>
                    <select class="form-control custom-select" name="col-lg">
                        <option value="">None</option>
                        ${largeSelOpt}
                    </select>
                    <br/>
                </div>
                ${(typeof hide_remove === 'undefined')?
                `<div class="col-12">
                    <button class="btn btn-sm btn-outline-light text-danger">
                        <i class="la la-trash la-lg"></i> Remove
                    </button>
                    
                </div>`:''}
            </div>`;
    }else if(type == 'pixelpages-input-textvalue'){
        return `<div class="row">
                    <div class="col-6 mb-1">
                        <label>Value</label>
                        <input name="value" type="text" value="${data.value}" class="form-control"/>
                    </div>

                    <div class="col-6 mb-1">
                        <label>Text</label>
                        <input name="text" type="text" value="${data.text}" class="form-control"/>
                    </div>
                    ${((typeof hide_remove === 'undefined')?`<div class="col-12">

                        <button class="btn btn-sm btn-outline-light text-danger">
                            <i class="la la-trash la-lg"></i> Remove
                        </button>
                        
                    </div>`:'')}
                </div>`
    }else if(type == 'pixelpages-input-rangeinput'){
        return `<div><input name="${data.key}" type="range" min="${data.min}" max="${data.max}" step="${data.step}" class="ed_range"/></div>`;
    }else if(type == 'pixelpages-input-imageinput'){
        return `<div><input name="${data.key}" type="text" class="form-control"/><input name="file" type="file" class="form-control"/></div>`;
    }else if(type == 'pixelpages-input-colorinput'){
        return `<div><input name="${data.key}" type="color" ${(typeof data.value !=='undefined' && data.value !=false)?`value="${data.value}"`:''}  pattern="#[a-f0-9]{6}" class="form-control ed_color"/></div>`;
    }else if(type == 'pixelpages-input-bootstrap-color-picker-input'){
        return `<div>
                    <div id="cp2" class="input-group" title="Using input value">
                    <input name="${data.key}" type="text" ${((typeof data.value !== 'undefined' && data.value != false)? `value="${data.value}"`:'' )} class="form-control"/>
                    <span class="input-group-append">
                        <span class="input-group-text colorpicker-input-addon"><i></i></span>
                    </span>
                    </div>
                </div>`;
    }else if(type == 'pixelpages-input-numberinput'){
        return '<div><div id="cp2" class="input-group" title="Using input value"> <input name="'+data.key+'" type="text" '+ (typeof data.value !=='undefined' && data.value !=false)?'value="'+data.value+'"':''+'class="form-control"/> <span class="input-group-append"><span class="input-group-text colorpicker-input-addon"><i></i></span> </span></div></div>';
    }else if(type == 'pixelpages-input-button'){
        return '<div><button class="btn btn-sm btn-primary"><i class="la'+(typeof data.icon !=='undefined')?data.icon:'la-plus la-lg"></i>'+data.text+'</button></div>'
    }else if (type == 'pixelpages-input-cssunitinput'){
        return `<div class="input-group" id="cssunit-${data.key}">
                    <input name="number" type="number" ${((typeof data.value !== 'undefined' && data.value != false)?`value="${data.value}"`:``)}
                    ${((typeof data.min !== 'undefined' && data.min != false)?`min="${data.min}"`:``)}
                    ${((typeof data.max !== 'undefined' && data.max != false)?`max="${data.max}"`:``)}
                    ${((typeof data.step !== 'undefined' && data.step != false)?`step="${data.step}"`:``)}
                    class="form-control"/>
                        <div class="input-group-append">
                    <select class="form-control custom-select small-arrow" name="unit">
                        <option value="em">em</option>
                        <option value="px">px</option>
                        <option value="%">%</option>
                        <option value="rem">rem</option>
                        <option value="auto">auto</option>
                    </select>
                    </div>
                </div>`;
    }else if (type == 'pixelpages-filemanager-folder'){
        return '<li data-folder="'+data.folder+'" class="folder"><label for="'+data.folder+'"><span>'+data.folderTitle+'</span></label> <input type="checkbox" id="'+data.folder+'"/><ol></ol></li>';
    }else if (type == 'pixelpages-filemanager-page'){
        return '<li data-url="'+data.url+'" data-page="'+data.name+'" class="file"><label for="'+data.name+'"><span>'+data.title+'</span></label> <input type="checkbox" checked id="'+data.name+'"/><ol></ol></li>';
    }else if (type == 'pixelpages-filemanager-component'){
        return '<li data-url="'+data.url+'" data-component="'+data.name+'" class="component"><a href="'+data.url+'"><span>'+data.title+'</span></a></li>';
    }else if (type == 'pixelpages-input-sectioninput'){
        return '<label class="header" data-header="'+data.key+'" for="header_'+data.key+'"><span>&ensp;'+data.header+'</span> <div class="header-arrow"></div></label> <input class="header_check" type="checkbox" '+((typeof data.expanded !=='undefined' && data.expanded==false)?'':'checked="true"')+'id="header_'+data.key+'"> <div class="section" data-section="'+data.key+'"></div>';
    }else if (type == 'pixelpages-input-autocompletelist'){
        return '<div><input name="'+data.key+'" type="text" class="form-control"/><div class="form-control autocomplete-list" style="min=height: 150px; overflow: auto;"> <div id="featured-product43"><i class="far fa-close"></i> MacBook <input name="product[]" value="43" type="hidden"> </div><div id="featured-product40"><i class="far fa-close"></i> iPhone <input name="product[]" value="40" type="hidden"> </div><div id="featured-product42"><i class="far fa-close"></i> Apple Cinema 30" <input name="product[]" value="42" type="hidden"> </div><div id="featured-product30"><i class="far fa-close"></i> Canon EOS 5D <input name="product[]" value="30" type="hidden"> </div></div></div>';
    }
}

export const tmpl = function tmpl(str, data){
    return cache[str] = PixelPagesTemplate(str , data);
};

/*
this.tmpl = function tmpl(str, data){
    // Figure out if we're getting a template, or if we need to
    // load the template - and be sure to cache the result.
	var fn = /^[-a-zA-Z0-9]+$/.test(str) ?
      cache[str] = cache[str] ||
        tmpl(document.getElementById(str).innerHTML) :
              
      // Generate a reusable function that will serve as a template
      // generator (and which will be cached).
      new Function("obj",
        "var p=[],print=function(){p.push.apply(p,arguments);};" +
         
        // Introduce the data as local variables using with(){}
        "with(obj){p.push('" +
         
        // Convert the template into pure JavaScript
        str
          .replace(/[\r\t\n]/g, " ")
          .split("{%").join("\t")
          .replace(/((^|%})[^\t]*)'/g, "$1\r")
          .replace(/\t=(.*?)%}/g, "',$1,'")
          .split("\t").join("');")
          .split("%}").join("p.push('")
          .split("\r").join("\\'")
      + "');}return p.join('');");
    // Provide some basic currying to the user
    return data ? fn( data ) : fn;
  }; */