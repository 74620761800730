import React, { Component } from 'react';
import css  from './css/panel.module.css';

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetSetting:'text'
        }
    }

    text = () =>{
        return(
            <>
                <div className="form-group">
                  <label className={css.ed_lable}>Heading</label>
                   <select class="form-control" name="" id="">
                     <option>1</option>
                     <option>2</option>
                     <option>3</option>
                   </select>
                </div>
                <hr className={css.ed_hr} />
                <div className="form-group">
                  <label className={css.ed_lable}>General</label>
                   <div className="row">
                       <div className="col">
                           <input name="" id="" class="form-control" type="text" value="" placeholder="Id" />
                       </div>
                       <div className="col">
                           <input name="" id="" class="form-control" type="text" value="" placeholder="Class"/>
                       </div>
                   </div>
                </div>
                <hr className={css.ed_hr} />
                <div className="form-group">
                    <label className={css.ed_lable}>Opacity</label>
                    <input name="opacity" type="range" min="0" max="1" step="0.1" className={css.ed_range}/>
                </div>
            </>
        );
    }

    render() {
       
        return (
            this.state.targetSetting === ''?'':
            <div className={css.ed_settingBox_wrapper} id="right-panel" style={{
                height: '500px'}}>
                <div className={css.ed_head}>
                     <h4 data-header="default">{this.state.targetSetting} Setting</h4>
                </div>
                <div className="component-properties ed_customScroll">
                    <ul class="nav nav-tabs nav-fill" id="properties-tabs" role="tablist">
                         <li class="nav-item content-tab">
                            <a class="nav-link active" data-toggle="tab" href="#content-tab" role="tab" aria-controls="components" aria-selected="true">
                                <i class="la la-lg la-cube"></i> <div><span>Content</span></div></a>
                        </li>
                        <li class="nav-item style-tab">
                            <a class="nav-link" data-toggle="tab" href="#style-tab" role="tab" aria-controls="blocks" aria-selected="false">
                                <i class="la la-lg la-image"></i> <div><span>Style</span></div></a>
                        </li>
                        {/* <li class="nav-item advanced-tab">
                        <a class="nav-link" data-toggle="tab" href="#advanced-tab" role="tab" aria-controls="blocks" aria-selected="false">
                            <i class="la la-lg la-cog"></i> <div><span>Advanced</span></div></a>
                        </li> */}
                    </ul>
                    <div className="tab-content">
                        <div class="tab-pane active show" id="content-tab" data-section="content"></div>    
                        <div class="tab-pane" id="style-tab" data-section="style"></div>
                        {/* <div class="tab-pane" id="advanced-tab" data-section="advanced"></div> */}
                    </div>
                </div>
            </div>
            
         );
    }
}
 
export default Setting;