import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Route } from 'react-router-dom';

import Main   from './component/Main';

class App extends Component{
  constructor(props) {
    super(props);
    this.state = {
      contentHeight:''
    }
  }  
  componentDidMount(){
      // document.onmouseover = this.handleMouse
  }
  // setIframeHeight = (iFrame) => {
  //   const iframeDOMNode = ReactDOM.findDOMNode(this.node)
  //   iframeDOMNode.height = iframeDOMNode.contentWindow.document.body.scrollHeight || 'auto'
  //   this.setState({
  //     contentHeight:iframeDOMNode.height
  //   }) 
  // }
  // handleMouse=()=>{
  //   console.log('dfgfdg')
  // }
  render() {  
    return (  
          <>
            <Route to="/" component={Main} />
            {/* <div className="editor-animation-manager-wrapper">
            <div className="editor-wrapper">
              <div className="editor-content">
                <div className="splitter-pane-horizantal">
                  <div className="splitter">
                    <div className="splitter-pane">
                      <div className="editing-area">
                        <div className="preview-frame-cover">
                        <div class="preview-container" ref={this.myRef} >
                              <iframe ref={x => this.node = x} onLoad={() => this.setIframeHeight(this)}  id="preview" src="template/template1/preview.html?isEdited=true&amp;isEditor=true&amp;isSantaEditor=true&amp;dsOrigin=Editor1.4&amp;controllersUrlOverride=&amp;lang=en&amp;metaSiteId=d20f73ac-e6cd-4501-b92e-5abbdd7ec194&amp;editorSessionId=4fa09b82-5b4c-43d3-a893-359abd236100&amp;esi=4fa09b82-5b4c-43d3-a893-359abd236100&amp;languages=" allowfullscreen=""></iframe>
                          </div>     

                          <div className="test_overlayDiv">
                            <div className="scrollable-editor-stage ">
                                <div className="site-cover"  style={{height:this.state.contentHeight+'px'}}>
                                    <div className="scalable">    
                                        <div className="mouseCatcher">     
                                            <div className="overlay"></div>
                                            <div> </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                  </div>
                </div>
              </div>
              </div> */}
          </>
    );  
  }  
}
 
export default App;  