import React, { Component } from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { }
    }
    render() { 
        return ( 
            <div className="ed_top_bar">
                {/* <div className="ed_create">
                    <h4 className="ed_blue">Dashboard</h4>
                </div> */}
                <div className ="ed_logo">
                    <a href="http://localhost:3000/"><img src="assets/images/user.png" alt="" className="" /></a> 
                </div>
                <div className="ed_user">
                    <img src="assets/images/user.png" alt="" />
                    <h6 className="ed_blue"><a href="#">Deepa</a> <span><i className="fas fa-chevron-down"></i></span></h6>
                    
                    <ul className="ed_userDropdown" style={{display:'none'}}>
                        <li><a href="#"> Edit Profile</a></li>
                        <li><a href="#">Logout</a></li> 
                    </ul>
                </div>
            </div>
        );     
    }
}       
   
export default Header;
