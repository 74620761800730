import React, { Component } from 'react';
import CommonVar from '../CommonAsset'
import css from '../css/editor.module.css'
import $ from 'jquery'
import 'jquery-ui-bundle';
import Settings from '../settings/Settings'


import PixelPagesBuilder  from '../pixelPages/PixelPages';
import Bootstrap4Components  from '../pixelPages/Bootstrap4Componant';
import Bootstrap4Clocks  from '../pixelPages/Bootstrap4Blocks';
// import serverComponent  from '../pixelPages/serverComponent';


class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elemName:'',
            positionTop:'',
            positionLeft:'',
            elemHeight:'',
            elemWidth:'',
            templateBlock : {
               
            }
          }
    }
    componentDidMount(){

      CommonVar.getAPI('api/builder/edit_campaign','get',{
        id : 1
      }).then((resp) => {
          console.log(resp);
          if(resp.status === 200){
              // PixelPagesBuilder.Components.componentPropertiesElement = "#left-panel .component-properties";
              PixelPagesBuilder.Builder.init(CommonVar.ApiUrl+resp.data.url , function(){

              });
              // PixelPagesBuilder.Builder.init('assets/template1/index.html' , function(){

              // });

              
              PixelPagesBuilder.Gui.init();
              PixelPagesBuilder.FileManager.init();
          }
      });

        
        

        $('#PixePagesTemplate').html(`<style> .mobile{ width: 320px !important; left: calc(50% - 175vw);left: calc(50% - ((320px / 2))); }.tablet {width: 768px !important;left: calc(50% - 399vw);left:calc(50% - ((768px / 2)))}
        #add-section-box
        {
          width: 50%;
          min-height: 300px;
          max-height: 400px;
          position: absolute;
          background: #fff;
          top: 100px;
          left: 100px;
          box-shadow: 0px 0px 2px 0px #ccc;
          border: 1px solid #ddd;
          min-width: 500px;
          max-width: 800px;
          pointer-events:auto;
          display:none;
          background:#fff;
        }#add-section-box 
        {
          .header > div.section-box-actions 
          {
            position:absolute;
            top:1rem;
            right:1rem;
          }
        
          .components-list, .blocks-list, .component-properties {
            width:auto;
            height:auto;
            padding: 0px;
            height: 200px;
            
            ol 
            {
              padding:0rem 0rem 0rem 1rem;
              
              li
              {
                width:14%;
                min-width:90px;
                float:left;
                margin:0% 1% 1% 1%;
                
                a
                {
                  display:block;
                  color: #333;
                  text-decoration: none;
                  text-shadow: none;
                  margin-top:5px;
                }
              }
            }
      
          }
          
                
          .blocks-list
          {
            ol 
            {
              li
              {
                @include block-list-element();
                width:29%;
                margin-right:1.5rem;
              }
            }
          }
        }</style>`);

        setTimeout(()=>{
            console.log($(window).scrollTop())
        },1000)
    }
    focusIframe = () =>{
        $("#preview").contents().find('body').mousemove(function (e) {
            console.log(e);
        });
    }
    
    publishCampign = () => {
      let pageData = PixelPagesBuilder.Builder.getHtml()
      .replace('"images/' , '"../../images/')
      // .replace('"images/' , '"../../images/')
      // .replace('"images/' , '"../../images/')
      CommonVar.getAPI('api/builder/publish','post',{
        pageData : pageData
      }).then((resp) => {
          console.log(resp);
          if(resp.status === 200){
              
          }
      });
    }


    render() { 
        return ( 
            <>
            <div id="pixelpages-builder">
                <div id="top-panel" className={css.topPane}>
                    <img src="assets/images/logo.png" alt="" id="logo" style={{width:'40px'}} />
                    <div>
                        <div className="btn-group mr-3" role="group">
                        <button className="btn btn-light" title="Undo (Ctrl/Cmd + Z)" id="undo-btn" data-pixelpages-action="undo" data-pixelpages-shortcut="ctrl+z">
                        <i className="far fa-undo"></i>
                        </button>

                        <button className="btn btn-light" title="Redo (Ctrl/Cmd + Shift + Z)" id="redo-btn" data-pixelpages-action="redo" data-pixelpages-shortcut="ctrl+shift+z">
                        <i className="far fa-undo fa-flip-horizontal"></i>
                        </button>
                        </div>
                        <div className="btn-group mr-3" role="group">
                        <button className="btn btn-light" title="Designer Mode (Free component dragging)" id="designer-mode-btn" data-toggle="button" aria-pressed="false" data-pixelpages-action="setDesignerMode">
                        <i className="far fa-hand-rock"></i>
                        </button>

                        <button className="btn btn-light" title="Preview" id="preview-btn" type="button" data-toggle="button" aria-pressed="false" data-pixelpages-action="preview">
                        <i className="far fa-eye"></i>
                        </button>

                        <button className="btn btn-light" title="Fullscreen (F11)" id="fullscreen-btn" data-toggle="button" aria-pressed="false" data-pixelpages-action="fullscreen">
                        <i className="far fa-arrows"></i>
                        </button>

                        <button className="btn btn-light" title="Download" id="download-btn" data-pixelpages-action="download" data-v-download="index.html">
                        <i className="far fa-download"></i>
                        </button>
                        </div>
                        </div>
                    <div>
                    <div className="btn-group mr-3 responsive-btns" role="group">
                    <button id="mobile-view" data-view="mobile" className="btn btn-light" title="Mobile view" data-pixelpages-action="viewport">
                    <i className="far fa-mobile"></i>
                    </button>

                    <button id="tablet-view" data-view="tablet" className="btn btn-light" title="Tablet view" data-pixelpages-action="viewport">
                    <i className="far fa-tablet"></i>
                    </button>

                    <button id="desktop-view" data-view="" className="btn btn-light" title="Desktop view" data-pixelpages-action="viewport">
                    <i className="far fa-laptop"></i>
                    </button>
                    </div>
                    <div className="btn-group mr-3" role="group">
                    <button className="btn btn-primary btn-icon" title="Export (Ctrl + E)" id="save-btn" data-pixelpages-action="save" data-pixelpages-url="/admin/?module=editor/editor&amp;action=save" data-v-pixelpages-shortcut="ctrl+e">
                    <i className="far fa-save"></i> <span data-v-gettext="">Save page</span>
                    </button>
                    <button className="btn btn-primary" title="" onClick={this.publishCampign}> <span data-v-gettext="">Publish</span>
                    </button>
                    </div>
                    </div>
                    </div>
                    <div id="left-panel">

                      <div id="filemanager" style={{display: 'none'}}> 
                        <div class="header">
                          <a href="#" class="text-secondary">Pages</a>

                            <div class="btn-group responsive-btns mr-4 float-right" role="group">
                              <button class="btn btn-link btn-sm" title="New file" id="new-file-btn" data-vvveb-action="newPage" data-vvveb-shortcut="">
                                <i class="la la-file"></i> <small>New page</small>
                              </button>
                              
                            </div>

                          </div>

                          <div class="tree">
                            <ol>
                            </ol>
                          </div>
                      </div>


                      <div class="drag-elements">

                      <div class="header">							
                        <ul class="nav nav-tabs" id="elements-tabs" role="tablist">
                          <li class="nav-item component-tab">
                          <a class="nav-link active" id="components-tab" data-toggle="tab" href="#components" role="tab" aria-controls="components" aria-selected="true"><i class="la la-lg la-cube"></i> <div><small>Components</small></div></a>
                          </li>
                          <li class="nav-item blocks-tab">
                          <a class="nav-link" id="blocks-tab" data-toggle="tab" href="#blocks" role="tab" aria-controls="blocks" aria-selected="false"><i class="la la-lg la-image"></i> <div><small>Blocks</small></div></a>
                          </li>
                          <li class="nav-item component-properties-tab" style={{display:'none'}}>
                          <a class="nav-link" id="properties-tab" data-toggle="tab" href="#properties" role="tab" aria-controls="blocks" aria-selected="false"><i class="la la-lg la-cog"></i> <div><small>Properties</small></div></a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          <div class="tab-pane fade show active" id="components" role="tabpanel" aria-labelledby="components-tab">
                            
                            <div class="search">
                                <input class="form-control form-control-sm component-search" placeholder="Search components" type="text" data-vvveb-action="componentSearch" data-vvveb-on="keyup" />
                                <button class="clear-backspace"  data-vvveb-action="clearComponentSearch">
                                  <i class="la la-close"></i>
                                </button>
                              </div>

                            <div class="drag-elements-sidepane sidepane">
                              <div>
                                
                              <ul class="components-list clearfix" data-type="leftpanel">
                              </ul>

                              </div>
                            </div>
                          
                          </div>
                          <div class="tab-pane fade" id="blocks" role="tabpanel" aria-labelledby="blocks-tab">
                            
                            <div class="search">
                                <input class="form-control form-control-sm block-search" placeholder="Search blocks" type="text" data-vvveb-action="blockSearch" data-vvveb-on="keyup" />
                                <button class="clear-backspace"  data-vvveb-action="clearBlockSearch">
                                  <i class="la la-close"></i>
                                </button>
                              </div>

                            <div class="drag-elements-sidepane sidepane">
                              <div>
                                
                              <ul class="blocks-list clearfix" data-type="leftpanel">
                              </ul>

                              </div>
                            </div>
                          
                          </div>
                        
                          <div class="tab-pane fade" id="properties" role="tabpanel" aria-labelledby="blocks-tab">
                            <div class="component-properties-sidepane">
                              <div>
                                <div class="component-properties">
                                  <ul class="nav nav-tabs nav-fill" id="properties-tabs" role="tablist">
                                      <li class="nav-item content-tab">
                                      <a class="nav-link active" data-toggle="tab" href="#content-left-panel-tab" role="tab" aria-controls="components" aria-selected="true">
                                        <i class="la la-lg la-cube"></i> <div><span>Content</span></div></a>
                                      </li>
                                      <li class="nav-item style-tab">
                                      <a class="nav-link" data-toggle="tab" href="#style-left-panel-tab" role="tab" aria-controls="blocks" aria-selected="false">
                                        <i class="la la-lg la-image"></i> <div><span>Style</span></div></a>
                                      </li>
                                      <li class="nav-item advanced-tab">
                                      <a class="nav-link" data-toggle="tab" href="#advanced-left-panel-tab" role="tab" aria-controls="blocks" aria-selected="false">
                                        <i class="la la-lg la-cog"></i> <div><span>Advanced</span></div></a>
                                      </li>
                                    </ul>
                                
                                    <div class="tab-content">
                                      <div class="tab-pane fade show active" id="content-left-panel-tab" data-section="content" role="tabpanel" aria-labelledby="content-tab">
                                        <div class="mt-4 text-center">Click on an element to edit.</div>
                                      </div>
                                      
                                      <div class="tab-pane fade show" id="style-left-panel-tab" data-section="style" role="tabpanel" aria-labelledby="style-tab">
                                      </div>
                                      
                                      <div class="tab-pane fade show" id="advanced-left-panel-tab" data-section="advanced"  role="tabpanel" aria-labelledby="advanced-tab">
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>							

                      </div>
                      </div>	

                <div id={css.canvas} data-id="canvas">
                    <div className={css.previewcontainer}>

                        <div className={css.iframe_layers} id="iframe-layer">
                            <div className={css.highlightbox} id="highlight-box"  style={{display:'block', top:this.state.positionTop, left:this.state.positionLeft, width:this.state.elemWidth, height:this.state.elemHeight}}>
                                <div className={css.highlightName} id="highlight-name">{this.state.elemName}</div>
                                
                                <div className={css.sectionActions} id="section-actions">
                                    <span id="add-section-btn" title="Add element"><i className="far fa-plus"></i></span>
                                </div>
                            </div>
                            <div id="select-box" className={css.selectBox}>

                                    <div id="wysiwyg-editor" className={css.wysiwygEditor} style={{display:" none"}}>
                                            <span id="bold-btn" title="Bold"><i><strong>B</strong></i></span>
                                            <span id="italic-btn" title="Italic"><i>I</i></span>
                                            <span id="underline-btn" title="Underline"><u>u</u></span>
                                            <span id="strike-btn" title="Strikeout"><del>S</del></span>
                                            <span id="link-btn" title="Create link"><strong>a</strong></span>
                                    </div>
                                    <div id="select-actions" className={css.selectActions}>
                                        <span id="drag-btn" title="Drag element"><i className="far fa-arrows"></i></span>
                                        <span id="parent-btn" title="Select parent"><i className="far fa-level-down fa-rotate-180"></i></span>
                                        
                                        <span id="up-btn" title="Move element up"><i className="far fa-arrow-up"></i></span>
                                        <span id="down-btn" title="Move element down"><i className="far fa-arrow-down"></i></span>
                                        <span id="clone-btn" title="Clone element"><i className="far fa-copy"></i></span>
                                        <span id="delete-btn" title="Remove element"><i className="far fa-trash"></i></span>
                                    </div>
                                </div>
                               
                               
                                <div id="add-section-box" className="drag-elements" style={{display:'none'}}>

                                  <div className="header">							
                                    <ul className="nav nav-tabs" id="box-elements-tabs" role="tablist">
                                        <li className="nav-item component-tab">
                                            <a className="nav-link active" id="box-components-tab" data-toggle="tab" href="#box-components" role="tab" aria-controls="components" aria-selected="true"><i className="la la-lg la-cube"></i> <div><small>Components</small></div></a>
                                        </li>
                                        <li className="nav-item blocks-tab">
                                            <a className="nav-link" id="box-blocks-tab" data-toggle="tab" href="#box-blocks" role="tab" aria-controls="blocks" aria-selected="false"><i className="la la-lg la-image"></i> <div><small>Blocks</small></div></a>
                                        </li>
                                        <li className="nav-item component-properties-tab" style={{display:'none'}}>
                                            <a className="nav-link" id="box-properties-tab" data-toggle="tab" href="#box-properties" role="tab" aria-controls="blocks" aria-selected="false"><i className="la la-lg la-cog"></i> <div><small>Properties</small></div></a>
                                        </li>
                                    </ul>
                                    
                                    <div className="section-box-actions">

                                      <div id="close-section-btn" className="btn btn-light btn-sm bg-white btn-sm float-right"><i class="far fa-times-circle"></i></div>
                                    
                                      <div className="small mt-1 mr-3 float-right">
                                      
                                        <div className="custom-control custom-radio custom-control-inline">
                                          <input type="radio" id="add-section-insert-mode-after" value="after" checked="checked" name="add-section-insert-mode" className="custom-control-input"/>
                                          <label className="custom-control-label" for="add-section-insert-mode-after">After</label>
                                        </div>
                                        
                                        <div className="custom-control custom-radio custom-control-inline">
                                          <input type="radio" id="add-section-insert-mode-inside" value="inside" name="add-section-insert-mode" className="custom-control-input"/>
                                          <label className="custom-control-label" for="add-section-insert-mode-inside">Inside</label>
                                        </div>
                                    
                                      </div>
                                      
                                    </div>
                                    
                                    <div className="tab-content">
                                      <div className="tab-pane fade show active" id="box-components" role="tabpanel" aria-labelledby="components-tab">
                                        
                                        <div className="search">
                                            <input className="form-control form-control-sm component-search" placeholder="Search components" type="text" data-vvveb-action="addBoxComponentSearch" data-vvveb-on="keyup"/>
                                            <button className="clear-backspace"  data-vvveb-action="clearComponentSearch">
                                              <i className="la la-close"></i>
                                            </button>
                                          </div>

                                        <div>
                                          <div>
                                            
                                          <ul className="components-list clearfix" data-type="addbox">
                                          </ul>

                                          </div>
                                        </div>
                                      
                                      </div>
                                      <div className="tab-pane fade" id="box-blocks" role="tabpanel" aria-labelledby="blocks-tab">
                                        
                                        <div className="search">
                                            <input className="form-control form-control-sm block-search" placeholder="Search blocks" type="text" data-vvveb-action="addBoxBlockSearch" data-vvveb-on="keyup"/>
                                            <button className="clear-backspace"  data-vvveb-action="clearBlockSearch">
                                              <i className="la la-close"></i>
                                            </button>
                                          </div>

                                        <div>
                                          <div>
                                            
                                          <ul className="blocks-list clearfix"  data-type="addbox">
                                          </ul>

                                          </div>
                                        </div>
                                      
                                      </div>
                                    </div>
                                  </div>		

                                  </div>      
                                
                                <Settings />
                        </div>

                        <iframe id="preview" src="" allowfullscreen=""></iframe>
                    </div>
                    
                    <div id="PixePagesTemplate"></div>


                </div>
                
                <div className="modal fade" id="textarea-modal" tabindex="-1" role="dialog" aria-labelledby="textarea-modal" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title text-primary"><i className="far fa-lg la-save"></i> Export html</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><small><i className="far fa-close"></i></small></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                            <textarea rows="25" cols="150" className="form-control"></textarea>
                        
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal"><i className="far fa-close"></i> Close</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default Preview;