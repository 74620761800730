import $ from 'jquery';
import {cache , tmpl} from './PixelPagesCommonVar';
import {SectionInput} from './PixelPagesInput';
import CommonVar , {customScroll} from '../CommonAsset'

console.log(CommonVar);

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  

function launchFullScreen(document) {
    if(document.documentElement.requestFullScreen) {
          if (document.FullScreenElement)
              document.exitFullScreen();
          else
              document.documentElement.requestFullScreen();
  //mozilla		
    }else if(document.documentElement.mozRequestFullScreen) {
  
          if (document.mozFullScreenElement)
              document.mozCancelFullScreen();
          else
              document.documentElement.mozRequestFullScreen();
  //webkit	  
    }else if(document.documentElement.webkitRequestFullScreen) {
  
          if (document.webkitFullscreenElement)
              document.webkitExitFullscreen();
          else
              document.documentElement.webkitRequestFullScreen();
  //ie	  
    } else if(document.documentElement.msRequestFullscreen) {
  
          if (document.msFullScreenElement)
              document.msExitFullscreen();
          else
              document.documentElement.msRequestFullscreen();
    }
}
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();
function isElement(obj){
    return (typeof obj==="object") &&
       (obj.nodeType===1) && (typeof obj.style === "object") &&
       (typeof obj.ownerDocument ==="object")/* && obj.tagName != "BODY"*/;
}
let PixelPagesObj = {
    defaultComponent : "_base",
    preservePropertySections : true,
    dragIcon : 'icon',//icon = use component icon when dragging | html = use component html to create draggable element
    baseUrl : window.location.hostname,
    baseUrl : CommonVar.baseUrl,
    ComponentsGroup : {},
    BlocksGroup : {},
    Builder : {
        component : {},
        dragMoveMutation : false,
        isPreview : false,
        runJsOnSetHtml : false,
        designerMode : false,
        init : function(url ,callback){
            var _self = this;
            
            _self.loadControlGroups();
            _self.loadBlockGroups();
            
            _self.selectedEl = null;
            _self.highlightEl = null;
            _self.initCallback = callback;
            _self.documentFrame = $("#preview");
            
            _self._loadMyIframe(url);
            _self._initBox();
            
            _self._initDragdrop();
            _self.dragElement = null;
        },
        /* controls */    	
        loadControlGroups : function() {	
            var componentsList = $(".components-list");
            componentsList.empty();
            var item = {}, component = {};
            var count = 0;
            componentsList.each(function ()
            {
                var list = $(this);
                var type = this.dataset.type;
                count ++;
                
                for (let group in PixelPagesObj.ComponentsGroup)	
                {
                    list.append('<li class="header clearfix" data-section="' + group + '"  data-search=""><label class="header" for="' + type + '_comphead_' + group + count + '">' + group + '  <div class="header-arrow"></div>\
                                            </label><input class="header_check" type="checkbox" checked="true" id="' + type + '_comphead_' + group + count + '">  <ol></ol></li>');
    
                    var componentsSubList = list.find('li[data-section="' + group + '"]  ol');
                    
                    let components = PixelPagesObj.ComponentsGroup[ group ];
                    
                    for (let i in components)
                    {
                        let componentType = components[i];
                        let component = PixelPagesObj.Components.get(componentType);
                        
                        if (component)
                        {
                            item = $('<li data-section="' + group + '" data-drag-type=component data-type="' + componentType + '" data-search="' + component.name.toLowerCase() + '"><a href="#">' + component.name + "</a></li>");
                            if (component.image) {
                                item.css({
                                    backgroundImage: "url("+PixelPagesObj.baseUrl+'/assets/images/' + component.image + ")",
                                    backgroundRepeat: "no-repeat"
                                })
                            }
                            componentsSubList.append(item)
                        }
                    }
                }
            });
        },  
        loadBlockGroups : function() {	
    
            var blocksList = $(".blocks-list");
            blocksList.empty();
            var item = {};
    
            blocksList.each(function ()
            {
    
                var list = $(this);
                var type = this.dataset.type;
    
                for (let group in PixelPagesObj.BlocksGroup)	
                {
                    list.append('<li class="header" data-section="' + group + '"  data-search=""><label class="header" for="' + type + '_blockhead_' + group + '">' + group + '  <div class="header-arrow"></div>\
                                            </label><input class="header_check" type="checkbox" checked="true" id="' + type + '_blockhead_' + group + '">  <ol></ol></li>');
    
                    var blocksSubList = list.find('li[data-section="' + group + '"]  ol');
                    let blocks = PixelPagesObj.BlocksGroup[ group ];
    
                    for (let i in blocks)
                    {
                        let blockType = blocks[i];
                        let block = PixelPagesObj.Blocks.get(blockType);
                        
                        if (block){
                            item = $('<li data-section="' + group + '" data-drag-type=block data-type="' + blockType + '" data-search="' + block.name.toLowerCase() + '"><a href="#">' + block.name + "</a></li>");
                            if (block.image) {
                                item.css({
                                    backgroundImage: "url(" + ((block.image.indexOf('//') == -1) ? 'libs/builder/':'') + block.image + ")",
                                    backgroundRepeat: "no-repeat"
                                })
                            }
                            blocksSubList.append(item)
                        }
                    }
                }
            });
        },
        loadUrl : function(url, callback) {	
            var self = this;
            $("#select-box").hide();
            
            self.initCallback = callback;
            if (PixelPagesObj.Builder.iframe.src != url) PixelPagesObj.Builder.iframe.src = url;
        },
        _loadMyIframe : function(url){
            var _self = this;
            _self.iframe = this.documentFrame.get(0);
            _self.iframe.src = url;
            
            return this.documentFrame.on("load", function(){
                window.FrameWindow = _self.iframe.contentWindow;
                window.FrameDocument = _self.iframe.contentWindow.document;
                var addSectionBox = $("#add-section-box"); 
                var highlightBox = $("#highlight-box").hide(); 
                
                $(window.FrameWindow).on( "beforeunload", function(event) {
                    // if (PixelPagesObj.Undo.undoIndex <= 0)
                    // {
                    // 	var dialogText = "You have unsaved changes";
                    // 	event.returnValue = dialogText;
                    // 	return dialogText;
                    // }
                });
                
                $(window.FrameWindow).on("scroll resize", function(event) {
                
                    if (_self.selectedEl){
                        var offset = _self.selectedEl.offset();
                        $("#select-box").css({
                            "top": offset.top - _self.frameDoc.scrollTop() , 
                            "left": offset.left - _self.frameDoc.scrollLeft() , 
                            "width" : _self.selectedEl.outerWidth(), 
                            "height": _self.selectedEl.outerHeight(),
                            //"display": "block"
                        });	
                        
                        $("#right-panel").css({
                            "top":offset.top - _self.frameDoc.scrollTop()+ _self.selectedEl.outerHeight() , 
                            "left": offset.left - _self.frameDoc.scrollLeft()
                        });    
                    }
                    
                    if (_self.highlightEl){
                        var offset = _self.highlightEl.offset();
                        
                        highlightBox.css({
                            "top": offset.top - _self.frameDoc.scrollTop() , 
                            "left": offset.left - _self.frameDoc.scrollLeft() , 
                            "width" : _self.highlightEl.outerWidth(), 
                            "height": _self.highlightEl.outerHeight(),
                            //"display": "block"
                        });		
                        addSectionBox.hide();
                    }
                        
                });
                
                PixelPagesObj.inlineEditor.init(window.FrameDocument);
                if (_self.initCallback) _self.initCallback();
                return _self._frameLoaded();
            });	
        },
        _frameLoaded : function() {
            var _self = this;
            _self.frameDoc = $(window.FrameDocument);
            _self.frameHtml = $(window.FrameDocument).find("html");
            _self.frameBody = $(window.FrameDocument).find("body");
            _self.frameHead = $(window.FrameDocument).find("head");
            
            //insert editor helpers like non editable areas
            // _self.frameHead.append('<link data-myBuilder-helpers href="' + myBuilder.baseUrl + '../../css/myBuilderjs-editor-helpers.css" rel="stylesheet">');
    
            _self._initHighlight();
            
            // $(window).triggerHandler("myBuilder.iframe.loaded", _self.frameDoc);
        },
        _getElementType : function(el) {
            //search for component attribute
            let componentName = '';  
            if (el.attributes)
                for (var j = 0; j < el.attributes.length; j++){
                    
                if (el.attributes[j].nodeName.indexOf('data-component') > -1){
                    componentName = el.attributes[j].nodeName.replace('data-component-', '');	
                }
                }
                
            if (componentName != '') return componentName;
    
            return el.tagName;
        },
        loadNodeComponent:  function(node) {
            let data = PixelPagesObj.Components.matchNode(node);
            var component = (data)?data.type: PixelPagesObj.defaultComponent;
            PixelPagesObj.Components.render(component);    
        },
        selectNode:  function(node) {
            var self = this;
            
            if (!node){
                $("#select-box").hide();
                return;
            }
            
            if (self.texteditEl && self.selectedEl.get(0) != node){
                PixelPagesObj.inlineEditor.destroy(self.texteditEl);
                $("#select-box").removeClass("text-edit").find("#select-actions").show();
                self.texteditEl = null;
            }
    
            var target = $(node);
            
            if (target){
                self.selectedEl = target;
    
                try {
                    var offset = target.offset();
                        
                    $("#select-box").css(
                        {"top": offset.top - self.frameDoc.scrollTop() , 
                         "left": offset.left - self.frameDoc.scrollLeft() , 
                         "width" : target.outerWidth(), 
                         "height": target.outerHeight(),
                         "display": "block",
                         });
                    $("#right-panel").css(
                    {"top": offset.top - self.frameDoc.scrollTop()+target.outerHeight() , 
                        "left": offset.left - self.frameDoc.scrollLeft(),
                        "display": "block",
                    });     
                    customScroll('.ed_customScroll','disable')
                    customScroll('.ed_customScroll','enable')
                } catch(err) {
                    console.log(err);
                    return false;
                }
            }   
            $("#highlight-name").html(this._getElementType(node));
        },
        _initHighlight : function() {
            var _self = this;
            _self.frameHtml.on("mousemove touchmove", function(event) {
                if (event.target && isElement(event.target) && event.originalEvent){
                    let target = $(event.target);
                    _self.highlightEl = target;
                    var offset = target.offset();
                    var height = target.outerHeight();
                    var halfHeight = Math.max(height / 2, 50);
                    var width = target.outerWidth();
                    var halfWidth = Math.max(width / 2, 50);
                    
                    var x = (event.clientX || event.originalEvent.clientX);
                    var y = (event.clientY || event.originalEvent.clientY);
                    
                    if (_self.isDragging){
                        var parent = _self.highlightEl;
    
                        try {
                            if (event.originalEvent) {
                                if ((offset.top  < (y - halfHeight)) || (offset.left  < (x - halfWidth))){
                                     if (isIE11) 
                                     _self.highlightEl.append(_self.dragElement); 
                                     else 
                                     _self.dragElement.appendTo(parent);
                                } else{
                                    if (isIE11) 
                                    _self.highlightEl.prepend(_self.dragElement); 
                                    else 
                                    _self.dragElement.prependTo(parent);
                                };
                                
                                if (_self.designerMode) {
                                    var parentOffset = _self.dragElement.offsetParent().offset();
    
                                    _self.dragElement.css({
                                        "position": "absolute",
                                        'left': x - (parentOffset.left - _self.frameDoc.scrollLeft()), 
                                        'top': y - (parentOffset.top - _self.frameDoc.scrollTop()),
                                        });
                                    
                                }
                            }
                            
                        } catch(err) {
                            console.log(err);
                            return false;
                        }
                        
                        if (!_self.designerMode && _self.iconDrag) _self.iconDrag.css({'left': x + 275/*left panel width*/, 'top':y - 30 });					
                    }// else //uncomment else to disable parent highlighting when dragging
                    else{
                        
                        $("#highlight-box").css(
                            {"top": offset.top - _self.frameDoc.scrollTop() , 
                             "left": offset.left - _self.frameDoc.scrollLeft() , 
                             "width" : width, 
                             "height": height,
                              "display" : event.target.hasAttribute('contenteditable')?"none":"block",
                              "border":_self.isDragging?"1px dashed aqua":"",//when dragging highlight parent with green
                             });
                                 
                        if (height < 50){
                            $("#section-actions").addClass("outside");	 
                        } else{
                            $("#section-actions").removeClass("outside");	
                        }
                        $("#highlight-name").html(_self._getElementType(event.target));
                        if (_self.isDragging) $("#highlight-name").hide(); else $("#highlight-name").show();//hide tag name when dragging
                    }
                }	
                
            });
            
            _self.frameHtml.on("mouseup touchend", function(event) {
                if (_self.isDragging){
                    _self.isDragging = false;
                    if (_self.iconDrag) _self.iconDrag.remove();
                    $("#component-clone").remove();
    
                    if (_self.dragMoveMutation === false){				
                        if (_self.component.dragHtml){ //if dragHtml is set for dragging then set real component html
                            let newElement = $(_self.component.html);
                            _self.dragElement.replaceWith(newElement);
                            _self.dragElement = newElement;
                        }
                        if (_self.component.afterDrop) _self.dragElement = _self.component.afterDrop(_self.dragElement);
                    }
                    
                    _self.dragElement.css("border", "");
                    
                    let node = _self.dragElement.get(0);
                    _self.selectNode(node);
                    console.log(node);
                    _self.loadNodeComponent(node);
    
                    if (_self.dragMoveMutation === false){
                        PixelPagesObj.Undo.addMutation({type: 'childList', 
                                                target: node.parentNode, 
                                                addedNodes: [node], 
                                                nextSibling: node.nextSibling});
                    } else{
                        
                        _self.dragMoveMutation.newParent = node.parentNode;
                        _self.dragMoveMutation.newNextSibling = node.nextSibling;
                        
                        PixelPagesObj.Undo.addMutation(_self.dragMoveMutation);
                        _self.dragMoveMutation = false;
                    }
                }
            });
    
            _self.frameHtml.on("dblclick", function(event) {
                
                if (_self.isPreview == false){
                    let target = $(event.target);
                    _self.texteditEl = target;
    
                    PixelPagesObj.inlineEditor.edit(_self.texteditEl);
                    
                    _self.texteditEl.attr({'contenteditable':true, 'spellcheckker':false});
                    
                    _self.texteditEl.on("blur keyup paste input", function(event) {
    
                        $("#select-box").css({
                                "width" : _self.texteditEl.outerWidth(), 
                                "height": _self.texteditEl.outerHeight()
                             });
                    });		
                    
                    $("#select-box").addClass("text-edit").find("#select-actions").hide();
                    $("#highlight-box").hide();
                }
            });
            
            
            _self.frameHtml.on("click", function(event) {
                
                if (_self.isPreview == false){
                    if (event.target){
                        //if component properties is loaded in left panel tab instead of right panel show tab
                        if ($(".component-properties-tab").is(":visible"))//if properites tab is enabled/visible 
                            $('.component-properties-tab a').show().tab('show'); 
                            
                            _self.selectNode(event.target);
                            _self.loadNodeComponent(event.target);
                    }
                    
                    if(!$(event.target).closest('.add-section-btn').length && !$(event.target).hasClass('.add-section-btn')){
                        $('#add-section-box').hide();
                    } 
                    
                    event.preventDefault();
                    return false;
                }	
                
            });
            
        },
        _initBox: function() {
            var _self = this;
            $("#drag-btn").on("mousedown", function(event) {
                $("#select-box").hide();
                _self.dragElement = _self.selectedEl.css("position","");
                _self.isDragging = true;
                
                let node = _self.dragElement.get(0);
                
                _self.dragMoveMutation = {
                                            type: 'move', 
                                            target: node,
                                            oldParent: node.parentNode,
                                            oldNextSibling: node.nextSibling
                                        };
                                     
                //self.selectNode(false);
                event.preventDefault();
                return false;
            });
            
            $("#down-btn").on("click", function(event) {
                $("#select-box").hide();
    
                let node = _self.selectedEl.get(0);
                let oldParent = node.parentNode;
                let oldNextSibling = node.nextSibling;
    
                let next = _self.selectedEl.next();
                
                if (next.length > 0){
                    next.after(_self.selectedEl);
                } else{
                    _self.selectedEl.parent().after(_self.selectedEl);
                }
                
                let newParent = node.parentNode;
                let newNextSibling = node.nextSibling;
                
                PixelPagesObj.Undo.addMutation({type: 'move', 
                                        target: node,
                                        oldParent: oldParent,
                                        newParent: newParent,
                                        oldNextSibling: oldNextSibling,
                                        newNextSibling: newNextSibling});
    
                event.preventDefault();
                return false;
            });
            
            $("#up-btn").on("click", function(event) {
                $("#select-box").hide();
    
                let node = _self.selectedEl.get(0);
                let oldParent = node.parentNode;
                let oldNextSibling = node.nextSibling;
    
                let next = _self.selectedEl.prev();
                
                if (next.length > 0)  {
                    next.before(_self.selectedEl);
                } else {
                    _self.selectedEl.parent().before(_self.selectedEl);
                }
    
                let newParent = node.parentNode;
                let newNextSibling = node.nextSibling;
                
                PixelPagesObj.Undo.addMutation({type: 'move', 
                                        target: node,
                                        oldParent: oldParent,
                                        newParent: newParent,
                                        oldNextSibling: oldNextSibling,
                                        newNextSibling: newNextSibling});
    
                event.preventDefault();
                return false;
            });
            
            $("#clone-btn").on("click", function(event) {
                let clone = _self.selectedEl.clone();
                _self.selectedEl.after(clone);
                _self.selectedEl = clone.click();
                let node = clone.get(0);
                PixelPagesObj.Undo.addMutation({type: 'childList', 
                                        target: node.parentNode, 
                                        addedNodes: [node],
                                        nextSibling: node.nextSibling});
                
                event.preventDefault();
                return false;
            });
            
            $("#parent-btn").on("click", function(event) {
                let node = _self.selectedEl.parent().get(0);
                _self.selectNode(node);
                _self.loadNodeComponent(node);
                event.preventDefault();
                return false;
            });
    
            $("#delete-btn").on("click", function(event) {
                $("#select-box").hide();
                let node = _self.selectedEl.get(0);
                PixelPagesObj.Undo.addMutation({type: 'childList', 
                                        target: node.parentNode, 
                                        removedNodes: [node],
                                        nextSibling: node.nextSibling});
    
                                        _self.selectedEl.remove();
                event.preventDefault();
                return false;
            });
    
            var addSectionBox = $("#add-section-box");
            var addSectionElement = {};
            
            $("#add-section-btn").on("click", function(event) {
                addSectionElement = _self.highlightEl; 
                var offset = $(addSectionElement).offset();			
                var top = (offset.top - _self.frameDoc.scrollTop()) + addSectionElement.outerHeight();
                var left = (offset.left - _self.frameDoc.scrollLeft()) + (addSectionElement.outerWidth() / 2) - (addSectionBox.outerWidth() / 2);
                var outerHeight = $(window.FrameWindow).height() + _self.frameDoc.scrollTop();
    
                //check if box is out of viewport and move inside
                if (left < 0) left = 0;
                if (top < 0) top = 0;
                if ((left + addSectionBox.outerWidth()) > _self.frameDoc.outerWidth()) left = _self.frameDoc.outerWidth() - addSectionBox.outerWidth();
                if (((top + addSectionBox.outerHeight()) + _self.frameDoc.scrollTop()) > outerHeight) top = top - addSectionBox.outerHeight();
                
                addSectionBox.css(
                    {"top": top, 
                     "left": left, 
                     "display": "block",
                     });
                
                event.preventDefault();
                return false;
            });
            
            $("#close-section-btn").on("click", function(event) {
                addSectionBox.hide();
            });
            
            function addSectionComponent(html, after = true) 
            {
                var node = $(html);
                
                if (after) {
                    addSectionElement.after(node);
                } else {
                    addSectionElement.append(node);
                }
                node = node.get(0);
                PixelPagesObj.Undo.addMutation({type: 'childList', 
                                        target: node.parentNode, 
                                        addedNodes: [node], 
                                        nextSibling: node.nextSibling});
            }
            
            $(".components-list li ol li", addSectionBox).on("click", function(event) {
                var html = PixelPagesObj.Components.get(this.dataset.type).html;
                addSectionComponent(html, ($("[name='add-section-insert-mode']:checked").val() == "after"));
                addSectionBox.hide();
            });
    
            $(".blocks-list li ol li", addSectionBox).on("click", function(event) {
                var html = PixelPagesObj.Blocks.get(this.dataset.type).html;
                addSectionComponent(html, ($("[name='add-section-insert-mode']:checked").val() == "after"));
                addSectionBox.hide();
            });
            
        },
        /* drag and drop */
        _initDragdrop : function() {
            var self = this;
            self.isDragging = false;	
            $('.drag-elements-sidepane ul > li > ol > li').on("mousedown touchstart", function(event) {
                let $this = $(this);
                $("#component-clone").remove();
                if ($this.data("drag-type") == "component")
                    self.component = PixelPagesObj.Components.get($this.data("type"));
                else
                    self.component = PixelPagesObj.Blocks.get($this.data("type"));
                
                if (self.component.dragHtml) {
                    var html = self.component.dragHtml;
                } else {
                    var html = self.component.html;
                }
                
                self.dragElement = $(html);
                self.dragElement.css("border", "1px dashed #4285f4");
                
                if (self.component.dragStart) self.dragElement = self.component.dragStart(self.dragElement);
    
                self.isDragging = true;
                if (PixelPagesObj.dragIcon == 'html'){
                    self.iconDrag = $(html).attr("id", "dragElement-clone").css('position', 'absolute');
                } else if (self.designerMode == false){
                    self.iconDrag = $('<img src=""/>').attr({"id": "dragElement-clone", 'src': $this.css("background-image").replace(/^url\(['"](.+)['"]\)/, '$1')}).
                    css({'z-index':100, 'position':'absolute', 'width':'64px', 'height':'64px', 'top': event.originalEvent.y, 'left': event.originalEvent.x});
                }
                    
                $('body').append(self.iconDrag);
                
                event.preventDefault();
                return false;
            });
            
            $('body').on('mouseup touchend', function(event) {
                if (self.iconDrag && self.isDragging == true){
                    self.isDragging = false;
                    $("#component-clone").remove();
                    self.iconDrag.remove();
                    if(self.dragElement){
                        self.dragElement.remove();
                    }
                }
            });
            
            $('body').on('mousemove touchmove', function(event) {
                if (self.iconDrag && self.isDragging == true){
                    var x = (event.clientX || event.originalEvent.clientX);
                    var y = (event.clientY || event.originalEvent.clientY);
    
                    self.iconDrag.css({'left': x - 60, 'top': y - 30});
    
                    var elementMouseIsOver = document.elementFromPoint(x - 60, y - 40);
                    
                    //if drag elements hovers over iframe switch to iframe mouseover handler	
                    if (elementMouseIsOver && elementMouseIsOver.tagName == 'IFRAME'){
                        self.frameBody.trigger("mousemove", event);
                        event.stopPropagation();
                        self.selectNode(false);
                    }
                }
            });
            
            $('.drag-elements-sidepane ul > ol > li > li').on("mouseup touchend", function(event) {
                self.isDragging = false;
                $("#component-clone").remove();
            });
                
        },
        
        removeHelpers: function (html, keepHelperAttributes = false){
            //tags like stylesheets or scripts 
            html = html.replace(/<.*?data-vvveb-helpers.*?>/gi, "");
            //attributes
            if (!keepHelperAttributes){
                html = html.replace(/\s*data-vvveb-\w+(=["'].*?["'])?\s*/gi, "");
            }
            return html;
        },
    
        getHtml: function(keepHelperAttributes = true) {
            var doc = window.FrameDocument;
            var hasDoctpe = (doc.doctype !== null);
            var html = "";
            $(window).triggerHandler("PixelPagesObj.getHtml.before", doc);
            
            if (hasDoctpe) html =
            "<!DOCTYPE "
                + doc.doctype.name
                + (doc.doctype.publicId ? ' PUBLIC "' + doc.doctype.publicId + '"' : '')
                + (!doc.doctype.publicId && doc.doctype.systemId ? ' SYSTEM' : '') 
                + (doc.doctype.systemId ? ' "' + doc.doctype.systemId + '"' : '')
                + ">\n";
                
                html +=  doc.documentElement.innerHTML + "\n</html>";
                
                html = this.removeHelpers(html, keepHelperAttributes);
                
                var filter = $(window).triggerHandler("PixelPagesObj.getHtml.after", html);
                if (filter) return filter;
                
                return html;
        },
        
        setHtml: function(html) {
            //update only body to avoid breaking iframe css/js relative paths
            var start = html.indexOf("<body");
            var end = html.indexOf("</body");		
    
            if (start >= 0 && end >= 0) {
                var body = html.slice(html.indexOf(">", start) + 1, end);
            } else {
                var body = html
            }
            
            if (this.runJsOnSetHtml)
                this.frameBody.html(body);
            else
                window.FrameDocument.body.innerHTML = body;
            
            
            //below methods brake document relative css and js paths
            //return self.iframe.outerHTML = html;
            //return self.documentFrame.html(html);
            //return self.documentFrame.attr("srcdoc", html);
        },
        
        saveAjax: function(fileName, startTemplateUrl, callback){
            var data = {};
            data["fileName"] = (fileName && fileName != "") ? fileName : PixelPagesObj.FileManager.getCurrentUrl();
            data["startTemplateUrl"] = startTemplateUrl;
            if (!startTemplateUrl || startTemplateUrl == null) {
                data["html"] = this.getHtml();
            }
    
            $.ajax({
                type: "POST",
                url: 'save.php',//set your server side save script url
                data: data,
                cache: false,
                success: function (data) {
                    if (callback) callback(data);
                },
                error: function (data) {
                    alert(data.responseText);
                }
            });					
        },
        
        setDesignerMode: function(designerMode = false){
            this.designerMode = designerMode;
        }
    } ,
    
    StyleManager : {
        setStyle: function(element, styleProp, value) {
            return element.css(styleProp, value);
        },
        _getCssStyle: function(element, styleProp){
            var value = "";
            var el = element.get(0);
            if (el.style && el.style.length > 0 && el.style[styleProp])//check inline
                var value = el.style[styleProp];
            else
            if (el.currentStyle)	//check defined css
                var value = el.currentStyle[styleProp];
            else if (window.getComputedStyle){
                var value = document.defaultView.getDefaultComputedStyle ? 
                                document.defaultView.getDefaultComputedStyle(el,null).getPropertyValue(styleProp) : 
                                window.getComputedStyle(el,null).getPropertyValue(styleProp);
            }
            
            return value;
        },
        
        getStyle: function(element,styleProp){
            return this._getCssStyle(element, styleProp);
        }
    },
    ContentManager : {
        getAttr: function(element, attrName) {
            return element.attr(attrName);
        },
        
        setAttr: function(element, attrName, value) {
            return element.attr(attrName, value);
        },
        
        setHtml: function(element, html) {
            return element.html(html);
        },
        
        getHtml: function(element) {
            return element.html();
        },
    },
    Components : {
        _components: {},
        _nodesLookup: {},
        _attributesLookup: {},
        _classesLookup: {},
        _classesRegexLookup: {},
        componentPropertiesElement: "#right-panel .component-properties",
        componentPropertiesDefaultSection: "content",
        get : function(type) {
            return this._components[type];
        },
        extend : function(inheritType, type, data) {
            // alert('asds');
            var newData = data;
            let inheritData = this._components[inheritType];
            if (inheritData){
               newData = $.extend(true,{}, inheritData, data);
               newData.properties = $.merge( $.merge([], inheritData.properties?inheritData.properties:[]), data.properties?data.properties:[]);
            }
            
            //sort by order
            newData.properties.sort(function (a,b) {
                   if (typeof a.sort  === "undefined") a.sort = 0;
                   if (typeof b.sort  === "undefined") b.sort = 0;
   
                   if (a.sort < b.sort)
                       return -1;
                   if (a.sort > b.sort)
                       return 1;
                   return 0;
               });
   /*		 
           var output = array.reduce(function(o, cur) {
   
             // Get the index of the key-value pair.
             var occurs = o.reduce(function(n, item, i) {
               return (item.key === cur.key) ? i : n;
             }, -1);
   
             // If the name is found,
             if (occurs >= 0) {
   
               // append the current value to its list of values.
               o[occurs].value = o[occurs].value.concat(cur.value);
   
             // Otherwise,
             } else {
   
               // add the current item to o (but make sure the value is an array).
               var obj = {name: cur.key, value: [cur.value]};
               o = o.concat([obj]);
             }
   
             return o;
           }, newData.properties);		 
   */
           
           this.add(type, newData);
        },
        add: function(type, data) {
            // alert();
            data.type = type;
            this._components[type] = data;
            if (data.nodes)  {
                for (var i in data.nodes){	
                    this._nodesLookup[ data.nodes[i] ] = data;
                }
            }
            
            if (data.attributes)  {
                if (data.attributes.constructor === Array){
                    for (var i in data.attributes){	
                        this._attributesLookup[ data.attributes[i] ] = data;
                    }
                } else{
                    for (var i in data.attributes){	
                        if (typeof this._attributesLookup[i] === 'undefined'){
                            this._attributesLookup[i] = {};
                        }
    
                        if (typeof this._attributesLookup[i][ data.attributes[i] ] === 'undefined'){
                            this._attributesLookup[i][ data.attributes[i] ] = {};
                        }
    
                        this._attributesLookup[i][ data.attributes[i] ] = data;
                    }
                }
            }
            
            if (data.classes) {
                for (var i in data.classes){	
                    this._classesLookup[ data.classes[i] ] = data;
                }
            }
            
            if (data.classesRegex) {
                for (var i in data.classesRegex) {	
                    this._classesRegexLookup[ data.classesRegex[i] ] = data;
                }
            }
        },
        matchNode: function(node) {
            var component = {};
            
            if (!node || !node.tagName) return false;
            
            if (node.attributes && node.attributes.length){
                //search for attributes
                for (var i in node.attributes){
                    if (node.attributes[i]){
                        let attr = node.attributes[i].name;
                        let value = node.attributes[i].value;
        
                        if (attr in this._attributesLookup) {
                            component = this._attributesLookup[ attr ];
                            
                            //currently we check that is not a component by looking at name attribute
                            //if we have a collection of objects it means that attribute value must be checked
                            if (typeof component["name"] === "undefined") {
                                if (value in component){
                                    return component[value];
                                }
                            } else 
                            return component;
                        }
                    }
                }
                    
                for (var i in node.attributes) {
                    let attr = node.attributes[i].name;
                    let value = node.attributes[i].value;
                    
                    //check for node classes
                    if (attr == "class"){
                        let classes = value.split(" ");
                        
                        for (let j in classes) {
                            if (classes[j] in this._classesLookup)
                            return this._classesLookup[ classes[j] ];	
                        }
                        
                        for (let regex in this._classesRegexLookup) {
                            let regexObj = new RegExp(regex);
                            if (regexObj.exec(value)) {
                                return this._classesRegexLookup[ regex ];	
                            }
                        }
                    }
                }
            }
    
            let tagName = node.tagName.toLowerCase();
            if (tagName in this._nodesLookup) return this._nodesLookup[ tagName ];
        
            return false;
            //return false;
        },
        render: function(type) {
            var component = this._components[type];
            
            
            var componentsPanel = $(this.componentPropertiesElement);
            var defaultSection = this.componentPropertiesDefaultSection;
            var componentsPanelSections = {};
            /***show data attr in right side panel */
            $(this.componentPropertiesElement + " .tab-pane").each(function (){
                var sectionName = this.dataset.section;
                componentsPanelSections[sectionName] = $(this);
                $(this).html(''); //this code is forcefully added by me, I need to remove this code and check the repeat conetent in setting 
            });           
            
            if(componentsPanelSections.hasOwnProperty(defaultSection)){
                var section = componentsPanelSections[defaultSection].find('.section[data-section="default"]');
                
                if (!(this.preservePropertySections && section.length)){
                    let defaultTemplate = tmpl("pixelpages-input-sectioninput", {key:"default", header:component.name});
                    console.log(componentsPanelSections[defaultSection] , defaultTemplate);
                    componentsPanelSections[defaultSection].html('').append(defaultTemplate);
                    section = componentsPanelSections[defaultSection].find(".section");
                }
                
                componentsPanelSections[defaultSection].find('[data-header="default"] span').html(component.name);
                section.html("");
            }
            
            if (component.beforeInit) component.beforeInit(PixelPagesObj.Builder.selectedEl.get(0));
            
            var element;
            
            var fn = function(component, property) {
                return property.input.on('propertyChange', function (event, value, input) {
                        
                        var element = PixelPagesObj.Builder.selectedEl;
                        
                        if (property.child) element = element.find(property.child);
                        if (property.parent) element = element.parent(property.parent);
                        
                        if (property.onChange){
                            element = property.onChange(element, value, input, component);
                        }/* else */
                        if (property.htmlAttr)
                        {
                            let oldValue = element.attr(property.htmlAttr);
                            
                            if (property.htmlAttr == "class" && property.validValues) 
                            {
                                element.removeClass(property.validValues.join(" "));
                                element = element.addClass(value);
                            } else if (property.htmlAttr == "style") {
                                element = PixelPagesObj.StyleManager.setStyle(element, property.key, value);
                            } else if (property.htmlAttr == "innerHTML") {
                                element = PixelPagesObj.ContentManager.setHtml(element, value);
                            } else {
                                //if value is empty then remove attribute useful for attributes without values like disabled
                                if (value){
                                    element = element.attr(property.htmlAttr, value);
                                } else{
                                    element = element.removeAttr(property.htmlAttr);
                                }
                            }
                            
                            PixelPagesObj.Undo.addMutation({type: 'attributes', 
                                                    target: element.get(0), 
                                                    attributeName: property.htmlAttr, 
                                                    oldValue: oldValue, 
                                                    newValue: element.attr(property.htmlAttr)});
                        }
                        if (component.onChange)  {
                            element = component.onChange(element, property, value, input);
                        }
                        
                        if (!property.child && !property.parent) PixelPagesObj.Builder.selectNode(element);
                        
                        return element;
                });				
            };			
        
            var nodeElement = PixelPagesObj.Builder.selectedEl;
            
            for (var i in component.properties){
                var property = component.properties[i];
                var element = nodeElement;
                if (property.beforeInit) property.beforeInit(element.get(0)) 
                
                if (property.child) element = element.find(property.child);
                
                if (property.data) {
                    property.data["key"] = property.key;
                } else {
                    property.data = {"key" : property.key};
                }
                if (typeof property.group  === 'undefined') property.group = null;
                
                property.input = property.inputtype.init(property.data);
                
                if (property.init){
                    property.inputtype.setValue(property.init(element.get(0)));
                } else if (property.htmlAttr){
                    if (property.htmlAttr == "style"){
                        //value = element.css(property.key);//jquery css returns computed style
                        var value = PixelPagesObj.StyleManager.getStyle(element, property.key);//getStyle returns declared style
                    } else if (property.htmlAttr == "innerHTML"){
                        var value = PixelPagesObj.ContentManager.getHtml(element);
                    } else{
                        var value = element.attr(property.htmlAttr);
                    }
                    
                    //if attribute is class check if one of valid values is included as class to set the select
                    if (value && property.htmlAttr == "class" && property.validValues){
                        value = value.split(" ").filter(function(el) {
                            return property.validValues.indexOf(el) != -1
                        });
                    } 
                    property.inputtype.setValue(value);
                }
                
                fn(component, property);
                
                var propertySection = defaultSection;
                if (property.section){
                    propertySection = property.section;
                }

               
                if (property.inputtype == SectionInput){
                    section = componentsPanelSections[propertySection].find('.section[data-section="' + property.key + '"]');
                    
                    if (this.preservePropertySections && section.length) {
                        section.html("");
                    } else  {
                        componentsPanelSections[propertySection].append(property.input);
                        console.log(componentsPanelSections[propertySection]);
                        section = componentsPanelSections[propertySection].find('.section[data-section="' + property.key + '"]');
                    }
                }else {
                    let templateData = tmpl('pixelpages-property', property);
                    var row = $(templateData);
                    row.find('.input').append(property.input);
                    section.append(row);
                }
                
                if (property.inputtype.afterInit) {
                    property.inputtype.afterInit(property.input);
                }
            }
            if (component.init) component.init(PixelPagesObj.Builder.selectedEl.get(0));
        }
    },
    Gui : {
        
        init: function() {
            $("[data-pixelpages-action]").each(function () {
                let on = "click";
                if (this.dataset.vvvebOn) on = this.dataset.vvvebOn;
              
                $(this).on(on, PixelPagesObj.Gui[this.dataset.pixelpagesAction]);
                
                if (this.dataset.pixelpagesShortcut){
                    $(document).bind('keydown', this.dataset.pixelpagesShortcut, PixelPagesObj.Gui[this.dataset.pixelpagesAction]);
                    $(window.FrameDocument, window.FrameWindow).bind('keydown', this.dataset.pixelpagesShortcut, PixelPagesObj.Gui[this.dataset.pixelpagesAction]);
                }
            });
        },
        
        undo : function () {
            if (PixelPagesObj.inlineEditor.isActive) {
                PixelPagesObj.inlineEditor.undo();
            } else {
                PixelPagesObj.Undo.undo();
            }
            PixelPagesObj.Builder.selectNode();
        },
        
        redo : function () {
            if (PixelPagesObj.inlineEditor.isActive) {
                PixelPagesObj.inlineEditor.redo();
            } else{
                PixelPagesObj.Undo.redo();
            }
            PixelPagesObj.Builder.selectNode();
        },
        
        //show modal with html content
        save : function () {
            $('#textarea-modal textarea').val(PixelPagesObj.Builder.getHtml());
            window.$('#textarea-modal').modal();
        },
        
        //post html content through ajax to save to filesystem/db
        saveAjax : function () {
            var url = PixelPagesObj.FileManager.getCurrentUrl();
            
            return PixelPagesObj.Builder.saveAjax(url, null, function (data) {
                $('#message-modal').modal().find(".modal-body").html("File saved at: " + data);
            });		
        },
        
        download : function () {
            let filename = /[^\/]+$/.exec(PixelPagesObj.Builder.iframe.src)[0];
            let uriContent = "data:application/octet-stream,"  + encodeURIComponent(PixelPagesObj.Builder.getHtml());
            var link = document.createElement('a');
            if ('download' in link){
                link.dataset.download = filename;
                link.href = uriContent;
                link.target = "_blank";
                
                document.body.appendChild(link);
                let result = link.click();
                document.body.removeChild(link);
                link.remove();
            } else{
                // location.href = uriContent;
            }
        },
        
        viewport : function () {
            $('[data-id="canvas"]').attr("class", this.dataset.view);
        },
        
        toggleEditor : function () {
            $("#pixelpages-builder").toggleClass("bottom-panel-expand");
            $("#toggleEditorJsExecute").toggle();
            PixelPagesObj.CodeEditor.toggle();
        },
        
        toggleEditorJsExecute : function () {
            PixelPagesObj.Builder.runJsOnSetHtml = this.checked;
        },
        
        preview : function () {
            console.log(562656);
            (PixelPagesObj.Builder.isPreview == true)?PixelPagesObj.Builder.isPreview = false:PixelPagesObj.Builder.isPreview = true;
            $("#iframe-layer").toggle();
            $("#pixelpages-builder").toggleClass("preview");
        },
        
        fullscreen : function () {
            launchFullScreen(document); // the whole page
        },
        
        componentSearch : function () {
            let searchText = this.value;
            $("#left-panel .components-list li ol li").each(function () {
                let  $this = $(this);
                $this.hide();
                if ($this.data("search").indexOf(searchText) > -1) $this.show();
            });
        },
        
        clearComponentSearch : function () {
            $(".component-search").val("").keyup();
        },
        
        blockSearch : function () {
            let searchText = this.value;
            $("#left-panel .blocks-list li ol li").each(function () {
                let $this = $(this);
                $this.hide();
                if ($this.data("search").indexOf(searchText) > -1) $this.show();
            });
        },
        
        clearBlockSearch : function () {
            $(".block-search").val("").keyup();
        },
        
        addBoxComponentSearch : function () {
            let searchText = this.value;
            $("#add-section-box .components-list li ol li").each(function () {
                let $this = $(this);
                
                $this.hide();
                if ($this.data("search").indexOf(searchText) > -1) $this.show();
            });
        },
        addBoxBlockSearch : function () {
            let searchText = this.value;
            $("#add-section-box .blocks-list li ol li").each(function () {
                let $this = $(this);
                $this.hide();
                if ($this.data("search").indexOf(searchText) > -1) $this.show();
            });
        },
    //Pages, file/components tree 
        newPage : function () {
            var newPageModal = $('#new-page-modal');
            newPageModal.modal("show").find("form").off("submit").submit(function( event ) {
                var title = $("input[name=title]", newPageModal).val();
                var startTemplateUrl = $("select[name=startTemplateUrl]", newPageModal).val();
                var fileName = $("input[name=fileName]", newPageModal).val();
                
                //replace nonalphanumeric with dashes and lowercase for name
                var name = title.replace(/\W+/g, '-').toLowerCase();
                    //allow only alphanumeric, dot char for extension (eg .html) and / to allow typing full path including folders
                    fileName = fileName.replace(/[^A-Za-z0-9\.\/]+/g, '-').toLowerCase();
                
                //add your server url/prefix/path if needed
                var url = "" + fileName;
                PixelPagesObj.FileManager.addPage(name, title, url);
                event.preventDefault();
                return PixelPagesObj.Builder.saveAjax(url, startTemplateUrl, function (data) {
                        PixelPagesObj.FileManager.loadPage(name);
                        PixelPagesObj.FileManager.scrollBottom();
                        newPageModal.modal("hide");
                });
            });
        },
        
        deletePage : function () {
            
        },
        setDesignerMode : function () {
            //aria-pressed attribute is updated after action is called and we check for false instead of true
            var designerMode = this.attributes["aria-pressed"].value != "true";
            PixelPagesObj.Builder.setDesignerMode(designerMode);
        },
    //layout
        togglePanel: function (panel, cssVar) {
            var panel = $(panel);
            var body = $("body");
            var prevValue = body.css(cssVar);
            if (prevValue !== "0px") {
                panel.data("layout-toggle", prevValue);
                body.css(cssVar, "0px");
                panel.hide();
            } else{
                prevValue= panel.data("layout-toggle");
                body.css(cssVar, '');
                panel.show();
                
            }
        },
        toggleFileManager: function () {
            PixelPagesObj.Gui.togglePanel("#filemanager", "--builder-filemanager-height");
        },
        
        toggleLeftColumn: function () {
            PixelPagesObj.Gui.togglePanel("#left-panel", "--builder-left-panel-width");
        },
        
        toggleRightColumn: function () {
            PixelPagesObj.Gui.togglePanel("#right-panel", "--builder-right-panel-width");
            var rightColumnEnabled = this.attributes["aria-pressed"].value == "true";
            $("#pixelpages-builder").toggleClass("no-right-panel");
            $(".component-properties-tab").toggle();
            
            PixelPagesObj.Components.componentPropertiesElement = (rightColumnEnabled ? "#right-panel" :"#left-panel") +" .component-properties";
            if ($("#properties").is(":visible")) $('.component-tab a').show().tab('show'); 
        },
    },
    Undo : {
        undos: [],
        mutations: [],
        undoIndex: -1,
        enabled:true,
        /*		
        init: function() {
        },
        */	
        addMutation : function(mutation) {	
            /*
                this.mutations.push(mutation);
                this.undoIndex++;
            */
           
            PixelPagesObj.Builder.frameBody.trigger("PixelPagesObj.undo.add");
            this.mutations.splice(++this.undoIndex, 0, mutation);
         },
    
        restore : function(mutation, undo) {	
            switch (mutation.type) {
                case 'childList':
                
                    if (undo == true){
                        var addedNodes = mutation.removedNodes;
                        var removedNodes = mutation.addedNodes;
                    } else {//redo
                        var addedNodes = mutation.addedNodes;
                        var removedNodes = mutation.removedNodes;
                    }
                    
                    if (addedNodes) for(let i in addedNodes){
                        var node = addedNodes[i];
                        if (mutation.nextSibling) { 
                            mutation.nextSibling.parentNode.insertBefore(node, mutation.nextSibling);
                        } else{
                            mutation.target.append(node);
                        }
                    }
    
                    if (removedNodes) for(let i in removedNodes){
                        var node = removedNodes[i];
                        node.parentNode.removeChild(node);
                    }
                break;					
                case 'move':
                    if (undo == true){
                        var parent = mutation.oldParent;
                        var sibling = mutation.oldNextSibling;
                    } else {//redo
                        var parent = mutation.newParent;
                        var sibling = mutation.newNextSibling;
                    }
                  
                    if (sibling) {
                        sibling.parentNode.insertBefore(mutation.target, sibling);
                    } else{
                        parent.append(node);
                    }
                break;
                case 'characterData':
                  mutation.target.innerHTML = undo ? mutation.oldValue : mutation.newValue;
                  break;
                case 'attributes':
                    var value = undo ? mutation.oldValue : mutation.newValue;
    
                  if (value || value === false || value === 0)
                    mutation.target.setAttribute(mutation.attributeName, value);
                  else
                    mutation.target.removeAttribute(mutation.attributeName);
    
                break;
            }
            
            PixelPagesObj.Builder.frameBody.trigger("PixelPagesObj.undo.restore");
         },
         
        undo : function() {	
            if (this.undoIndex >= 0) {
              this.restore(this.mutations[this.undoIndex--], true);
            }
         },
    
        redo : function() {	
            if (this.undoIndex < this.mutations.length - 1) {
              this.restore(this.mutations[++this.undoIndex], false);
            }
        },
    
        hasChanges : function() {	
            return this.mutations.length;
        },
    },
    inlineEditor : {
        //WysiwygEditor
        isActive: false,
        oldValue: '',
        doc:false,
        
        init: function(doc) {
            this.doc = doc;
            
            $("#bold-btn").on("click", function (e) {
                    doc.execCommand('bold',false,null);
                    e.preventDefault();
                    return false;
            });
    
            $("#italic-btn").on("click", function (e) {
                    doc.execCommand('italic',false,null);
                    e.preventDefault();
                    return false;
            });
    
            $("#underline-btn").on("click", function (e) {
                    doc.execCommand('underline',false,null);
                    e.preventDefault();
                    return false;
            });
            
            $("#strike-btn").on("click", function (e) {
                    doc.execCommand('strikeThrough',false,null);
                    e.preventDefault();
                    return false;
            });
    
            $("#link-btn").on("click", function (e) {
                    doc.execCommand('createLink',false,"#");
                    e.preventDefault();
                    return false;
            });
        },
        
        undo: function(element) {
            this.doc.execCommand('undo',false,null);
        },
    
        redo: function(element) {
            this.doc.execCommand('redo',false,null);
        },
        
        edit: function(element) {
            element.attr({'contenteditable':true, 'spellcheckker':false});
            $("#wysiwyg-editor").show();
    
            this.element = element;
            this.isActive = true;
            this.oldValue = element.html();
        },
    
        destroy: function(element) {
            element.removeAttr('contenteditable spellcheckker');
            $("#wysiwyg-editor").hide();
            this.isActive = false;
    
        
            let node = this.element.get(0);
            PixelPagesObj.Undo.addMutation({
                                    type:'characterData', 
                                    target: node, 
                                    oldValue: this.oldValue, 
                                    newValue: node.innerHTML
                                });
        }
    },
    FileManager : {
        tree:false,
        pages:{},
        currentPage: false,
        
        init: function() {
            this.tree = $("#filemanager .tree > ol").html("");
            
            $(this.tree).on("click", "a", function (e) {
                e.preventDefault();
                return false;
            });
            
            $(this.tree).on("click", "li[data-page] label", function (e) {
                var page = $(this.parentNode).data("page");
                
                if (page) PixelPagesObj.FileManager.loadPage(page);
                return false;			
            })
            
            $(this.tree).on("click", "li[data-component] label ", function (e) {
                let node = $(e.currentTarget.parentNode).data("node");
                
                PixelPagesObj.Builder.frameHtml.animate({
                    scrollTop: $(node).offset().top
                }, 1000);
    
                PixelPagesObj.Builder.selectNode(node);
                PixelPagesObj.Builder.loadNodeComponent(node);
                
                //e.preventDefault();
                //return false;
            }).on("mouseenter", "li[data-component] label", function (e) {
                let node = $(e.currentTarget).data("node");
                $(node).trigger("mousemove");
            });
        },
        
        addPage: function(name, data) {
            this.pages[name] = data;
            data['name'] = name;
            var folder = this.tree;
            if (data.folder){
                if (!(folder = this.tree.find('li[data-folder="' + data.folder + '"]')).length){
                    data.folderTitle = data.folder[0].toUpperCase() + data.folder.slice(1);
                    folder = $(tmpl("pixelpages-filemanager-folder", data));
                    this.tree.append(folder);
                }
                folder = folder.find("> ol");
            } 
            
            folder.append( tmpl("pixelpages-filemanager-page", data));
        },
        
        addPages: function(pages) {
            for (let page in pages) {
                this.addPage(pages[page]['name'], pages[page]);
            }
        },
        
        addComponent: function(name, url, title, page) {
            $("[data-page='" + page + "'] > ol", this.tree).append(tmpl("pixelpages-filemanager-component", {name:name, url:url, title:title}));
        },
        
        getComponents: function(allowedComponents = {}) {
                var tree = [];
                function getNodeTree (node, parent) {
                    if (node.hasChildNodes()) {
                        for (var j = 0; j < node.childNodes.length; j++) {
                            let child = node.childNodes[j];
                            let matchChild = PixelPagesObj.Components.matchNode(child)
                            if (child && child["attributes"] != undefined && (matchChild))  {
                                if (Array.isArray(allowedComponents)
                                    && allowedComponents.indexOf(matchChild.type) == -1)
                                continue;
                            
                                var element = {
                                    name: matchChild.name,
                                    image: matchChild.image,
                                    type: matchChild.type,
                                    node: child,
                                    children: []
                                };
                                element.children = [];
                                parent.push(element);
                                element = getNodeTree(child, element.children);
                            } else{
                                var element = getNodeTree(child, parent);	
                            }
                        }
                    }
                    return false;
                }
            
            getNodeTree(window.FrameDocument.body, tree);
            
            return tree;
        },
        
        loadComponents: function(allowedComponents = {}) {
    
            var tree = this.getComponents(allowedComponents);
            var html = drawComponentsTree(tree);
            var j = 0;
    
            function drawComponentsTree(tree) {
                var html = $("<ol></ol>");
                j++;
                for (let i in tree){
                    var node = tree[i];
                    
                    if (tree[i].children.length > 0) {
                        var li = $('<li data-component="' + node.name + '">\
                        <label for="id' + j + '" style="background-image:url(libs/builder/' + node.image + ')"><span>' + node.name + '</span></label>\
                        <input type="checkbox" id="id' + j + '">\
                        </li>');		
                        li.data("node", node.node);
                        li.append(drawComponentsTree(node.children));
                        html.append(li);
                    }else {
                        var li =$('<li data-component="' + node.name + '" class="file">\
                                <label for="id' + j + '" style="background-image:url(libs/builder/' + node.image + ')"><span>' + node.name + '</span></label>\
                                <input type="checkbox" id="id' + j + '"></li>');
                        li.data("node", node.node);							
                        html.append(li);
                    }
                }
                
                return html;
            }
            
            $("[data-page='" + this.currentPage + "'] > ol", this.tree).replaceWith(html);
        },
        
        getCurrentUrl: function() {
            if (this.currentPage)
            return this.pages[this.currentPage]['url'];
        },
        
        reloadCurrentPage: function() {
            if (this.currentPage)
            return this.loadPage(this.currentPage);
        },
        
        loadPage: function(name, allowedComponents = false, disableCache = true) {
            $("[data-page]", this.tree).removeClass("active");
            $("[data-page='" + name + "']", this.tree).addClass("active");
            this.currentPage = name;
            var url = this.pages[name]['url'];
            PixelPagesObj.Builder.loadUrl(url + (disableCache ? (url.indexOf('?') > -1?'&':'?') + Math.random():''), 
                function () { 
                    PixelPagesObj.FileManager.loadComponents(allowedComponents); 
            });
        },
    
        scrollBottom: function() {
            var scroll = this.tree.parent();	
            scroll.scrollTop(scroll.prop("scrollHeight"));	
        },
    },
    Blocks : {
        _blocks: {},
        get: function(type) {
            return this._blocks[type];
        },
        add: function(type, data) {
            data.type = type;
            this._blocks[type] = data;
        },
    },
    CodeEditor : {
        isActive: false,
        oldValue: '',
        doc:false,
        init: function(doc) {
            $("#vvveb-code-editor textarea").val(PixelPagesObj.Builder.getHtml());
            $("#vvveb-code-editor textarea").keyup(function () {
                delay(PixelPagesObj.Builder.setHtml(this.value), 1000);
            });
    
            //load code on document changes
            PixelPagesObj.Builder.frameBody.on("PixelPagesObj.undo.add PixelPagesObj.undo.restore", function (e) { PixelPagesObj.CodeEditor.setValue();});
            //load code when a new url is loaded
            PixelPagesObj.Builder.documentFrame.on("load", function (e) { PixelPagesObj.CodeEditor.setValue();});
            this.isActive = true;
        },
    
        setValue: function(value) {
            if (this.isActive){
                $("#vvveb-code-editor textarea").val(PixelPagesObj.Builder.getHtml());
            }
        },
    
        destroy: function(element) {
            //this.isActive = false;
        },
    
        toggle: function() {
            if(this.isActive != true){
                this.isActive = true;
                return this.init();
            }
            this.isActive = false;
            this.destroy();
        }
    }
}
export default PixelPagesObj;