import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery'
import 'jquery-ui-bundle';


// import $ from 'jquery'

class Sidebar extends Component {
    constructor(props) {
        super(props);
        localStorage.removeItem('token')
        this.state = { 
            menuPopup:false
        }

        this.myRef = React.createRef();
    }
    componentDidMount(){
        // setTimeout(()=>{
        //     console.log( $('.draggable').length)
        //     $('.draggable').draggable();
        // },100)
    }

    menuSettingPopup=(targetlist)=>{
        console.log(this.myRef.current)
        console.log(targetlist)

      
        return(
            <>
                <div className="ed_menuPopup">
                    {/* {targetlist === 'addlink'? */}
                        <>
                            <h6 className="ed_bottompadder10 ed_title">Link Layouts <span onClick={()=>this.setState({menuPopup:false})}><i class="far fa-times-circle"></i></span></h6>
                            <ul className="draggable">
                                <li>
                                    <div className="ed_box">
                                           Layout 1
                                    </div>
                                </li>
                                <li>
                                    <div className="ed_box">
                                           Layout 2
                                    </div>
                                </li>
                                <li>
                                    <div className="ed_box">
                                           Layout 3
                                    </div>
                                </li>
                                <li>
                                    <div className="ed_box">
                                           Layout 4
                                    </div>
                                </li>
                            </ul>
                        </>
                    {/* :''} */}
                </div>
            </>
        );
    }

    handleMenu =(e)=>{
        let __target =e.target
        if(__target.tagName !== 'li'){
            __target = __target.parentElement.parentElement
        }
        let menu = __target.getAttribute('datanav')

        
        console.log(menu)
        this.setState({menuPopup:true})
        this.menuSettingPopup(menu)
       setTimeout(()=>{
            var operatorId = 0;
            var elementType = '';
            $('.draggable>li').draggable({
                cursor: "move",
                opacity: 0.7,
                helper: 'clone', 
                appendTo: 'body',
                zIndex: 1000,
                helper: function(e) {
                    elementType = $(this).attr('data-type');
                    var singleElement = $('<li>'+$(this)[0].innerHTML+'</li>');
                    singleElement.find('.msg_drag').remove();
                    return singleElement[0].innerHTML;
                },
                stop:( event, ui ) => {
                    const defaulData = {
                        id : 0,
                        layout : 1,
                        title : 'this is test default',
                        link : 'http://localhost:3000/',
                        images : '/assets/images/user.png'
                    }
                    
                    var newarray = [...this.props.items]
                    newarray.push(defaulData)
                    console.log(newarray)
                    this.props.dragitems({
                        items:newarray
                    })
                    this.setState({menuPopup:false})
                },
            });
          
       },100)
    }

    render() { 
        return ( 
            <>
            <div className ="ed_header text-center">
                {/* <div className ="ed_logo">
                    <a href="http://localhost:3000/"><img src="assets/images/user.png" alt="" className="" /></a> 
                </div> */}
                <div className ="ed_menu ed_custom_scroll">

                    <ul>
                        <li><NavLink  exact={true} activeClassName="ed_active" to="/" ><i className="fal fa-desktop-alt"></i>dashboard</NavLink ></li>
                        <li datanav="addlink" onClick={this.handleMenu}><NavLink   activeClassName="ed_active" to="/" ><i class="fal fa-plus-circle"></i>Add Link</NavLink ></li>
                        <li><NavLink   activeClassName="ed_active" to="/" ><i class="fal fa-chart-bar"></i>Analytics</NavLink ></li>
                    </ul> 
                </div>
            </div>

            {this.state.menuPopup && this.menuSettingPopup()}  
            </>
        );     
    }        
     




}       
   
export default Sidebar;
