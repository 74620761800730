
import $ from 'jquery';

import {tmpl} from './PixelPagesCommonVar';

export const  Input = {
	
	init: function(name) {
	},


	onChange: function(event, node) {
		
		if (event.data && event.data.element)
		{
			event.data.element.trigger('propertyChange', [this.value, this]);
		}
	},

	renderTemplate: function(name, data) {
		let inputTemplate = tmpl("pixelpages-input-" + name, data);
		return inputTemplate;
	},

	setValue: function(value) {
		$('input', this.element).val(value);
	},
	
	render: function(name, data) {
		this.element = $(this.renderTemplate(name, data));
		
		//bind events
		if (this.events)
		for (var i in this.events)
		{
			var ev = this.events[i][0];
			var fun = this[ this.events[i][1] ];
			var el = this.events[i][2];
		
			this.element.on(ev, el, {element: this.element, input:this}, fun);
		}

		return this.element;
	}
};

export const  TextInput = $.extend({}, Input, {

    events: [
        ["blur", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("textinput", data);
	},
  }
);

export const  TextareaInput = $.extend({}, Input, {

    events: [
        ["keyup", "onChange", "textarea"],
	 ],
	
	setValue: function(value) {
		$('textarea', this.element).val(value);
	},
	
	init: function(data) {
		return this.render("textareainput", data);
	},
  }
);

export const  CheckboxInput = $.extend({}, Input, {

	onChange: function(event, node) {
		
		if (event.data && event.data.element)
		{
			event.data.element.trigger('propertyChange', [this.checked, this]);
		}
	},

    events: [
        ["change", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("checkboxinput", data);
	},
  }
);

export const  SelectInput = $.extend({}, Input, {
	
    events: [
        ["change", "onChange", "select"],
	 ],
	

	setValue: function(value) {
		$('select', this.element).val(value);
	},
	
	init: function(data) {
		return this.render("select", data);
	},
	
  }
);


export const  LinkInput = $.extend({}, TextInput, {

    events: [
        ["change", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("textinput", data);
	},
  }
);

export const  RangeInput = $.extend({}, Input, {

    events: [
        ["change", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("rangeinput", data);
	},
  }
);

export const  NumberInput = $.extend({}, Input, {

    events: [
        ["change", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("numberinput", data);
	},
  }
);

export const  CssUnitInput = $.extend({}, Input, {

	number:0,
	unit:"px",
		
    events: [
        ["change", "onChange", "select"],
        ["change keyup mouseup", "onChange", "input"],
	 ],
		
	onChange: function(event) {
		
		if (event.data && event.data.element)
		{
			var input = event.data.input;
			if (this.value != "") input[this.name] = this.value;// this.name = unit or number	
			if (input['unit'] == "") input['unit'] = "px";//if unit is not set use default px
			
			var value = "";	
			if (input.unit == "auto")  
			{
				$(event.data.element).addClass("auto"); 
				value = input.unit;
			}
			else 
			{
				$(event.data.element).removeClass("auto"); 
				value = input.number + input.unit;
			}
			
			event.data.element.trigger('propertyChange', [value, this]);
		}
	},
	
	setValue: function(value) {
		this.number = parseInt(value);
		this.unit = value.replace(this.number, '');
		
		if (this.unit == "auto") $(this.element).addClass("auto");

		$('input', this.element).val(this.number);
		$('select', this.element).val(this.unit);
	},
	
	init: function(data) {
		return this.render("cssunitinput", data);
	},
  }
);

export const  ColorInput = $.extend({}, Input, {

	 //html5 color input only supports setting values as hex colors even if the picker returns only rgb
	 rgb2hex: function(rgb) {
		 
		 if (rgb)
		 {
		 rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		 
		 return (rgb && rgb.length === 4) ? "#" +
		  ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
		  ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
		  ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : rgb;
		 }
	},

    events: [
        ["change", "onChange", "input"],
	 ],

	setValue: function(value) {
		$('input', this.element).val(this.rgb2hex(value));
	},
	
	init: function(data) {
		return this.render("colorinput", data);
	},
  }
);

export const  ImageInput = $.extend({}, Input, {

    events: [
        ["blur", "onChange", "input[type=text]"],
        ["change", "onUpload", "input[type=file]"],
	 ],

	setValue: function(value) {

		//don't set blob value to avoid slowing down the page		
		if (value.indexOf("data:image") == -1)
		{
				$('input[type="text"]', this.element).val(value);
		}
	},

	onUpload: function(event, node) {

		if (this.files && this.files[0]) {
            var reader = new FileReader();
            reader.onload = imageIsLoaded;
            reader.readAsDataURL(this.files[0]);
            //reader.readAsBinaryString(this.files[0]);
            var file = this.files[0];
        }

		function imageIsLoaded(e) {
				
				var image = e.target.result;
				
				event.data.element.trigger('propertyChange', [image, this]);
				
				return;//remove this line to enable php upload

				var formData = new FormData();
				formData.append("file", file);
    
				$.ajax({
					type: "POST",
					url: 'upload.php',//set your server side upload script url
					data: formData,
					processData: false,
					contentType: false,
					success: function (data) {
						console.log("File uploaded at: ", data);
						
						//if image is succesfully uploaded set image url
						event.data.element.trigger('propertyChange', [data, this]);
						
						//update src input
						$('input[type="text"]', event.data.element).val(data);
					},
					error: function (data) {
						alert(data.responseText);
					}
				});		
		}
	},

	init: function(data) {
		return this.render("imageinput", data);
	},
  }
);

export const  FileUploadInput = $.extend({}, TextInput, {

    events: [
        ["blur", "onChange", "input"],
	 ],

	init: function(data) {
		return this.render("textinput", data);
	},
  }
);


export const  RadioInput = $.extend({}, Input, {

	onChange: function(event, node) {
		
		if (event.data && event.data.element)
		{
			event.data.element.trigger('propertyChange', [this.value, this]);
		}
	},

    events: [
        ["change", "onChange", "input"],
	 ],

	setValue: function(value) {
		$('input', this.element).removeAttr('checked');
		if (value)
		$("input[value=" + value + "]", this.element).attr("checked", "true").prop('checked', true);
	},
	
	init: function(data) {
		return this.render("radioinput", data);
	},
  }
);

export const  RadioButtonInput = $.extend({}, RadioInput, {

	setValue: function(value) {
		$('input', this.element).removeAttr('checked');
		$('btn', this.element).removeClass('active');
		if (value && value != "")
		{
			// $("input[value=" + value + "]", this.element).attr("checked", "true").prop('checked', true).parent().button("toggle");
		}
	},

	init: function(data) {
		return this.render("radiobuttoninput", data);
	},
  }
);

export const  ToggleInput = $.extend({}, TextInput, {

	onChange: function(event, node) {
		if (event.data && event.data.element)
		{
			event.data.element.trigger('propertyChange', [this.checked?this.getAttribute("data-value-on"):this.getAttribute("data-value-off"), this]);
		}
	},

    events: [
        ["change", "onChange", "input"],
	 ],

	init: function(data) {
		return this.render("toggle", data);
	},
  }
);

export const  ValueTextInput = $.extend({}, TextInput, {

    events: [
        ["blur", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("textinput", data);
	},
  }
);

export const  GridLayoutInput = $.extend({}, TextInput, {

    events: [
        ["blur", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("textinput", data);
	},
  }
);

export const  ProductsInput = $.extend({}, TextInput, {

    events: [
        ["blur", "onChange", "input"],
	 ],
	
	init: function(data) {
		return this.render("textinput", data);
	},
  }
);


export const  GridInput = $.extend({}, Input, {
	

    events: [
        ["change", "onChange", "select" /*'select'*/],
        ["click", "onChange", "button" /*'select'*/],
	 ],
	

	setValue: function(value) {
		$('select', this.element).val(value);
	},
	
	init: function(data) {
		return this.render("grid", data);
	},
	
  }
);

export const  TextValueInput = $.extend({}, Input, {
	

    events: [
        ["blur", "onChange", "input"],
	    ["click", "onChange", "button" /*'select'*/],
	 ],
	
	init: function(data) {
		return this.render("textvalue", data);
	},
	
  }
);

export const  ButtonInput = $.extend({}, Input, {

    events: [
        ["click", "onChange", "button" /*'select'*/],
	 ],
	

	setValue: function(value) {
		$('button', this.element).val(value);
	},
	
	init: function(data) {
		return this.render("button", data);
	},
	
  }
);

export const SectionInput = $.extend({}, Input, {

    events: [
        ["click", "onChange", "button" /*'select'*/],
	 ],
	

	setValue: function(value) {
		return false;
	},
	
	init: function(data) {
		return this.render("sectioninput", data);
	},
	
  }
);

export const  ListInput = $.extend({}, Input, {
	
    events: [
        ["change", "onChange", "select"],
	 ],
	

	setValue: function(value) {
		$('select', this.element).val(value);
	},
	
	init: function(data) {
		return this.render("listinput", data);
	},
	
  }
);



export const  AutocompleteInput = $.extend({}, Input, {

    events: [
        ["autocomplete.change", "onAutocompleteChange", "input"],
	 ],

	onAutocompleteChange: function(event, value, text) {
		
		if (event.data && event.data.element)
		{
			event.data.element.trigger('propertyChange', [value, this]);
		}
	},

	init: function(data) {
		
		this.element = this.render("textinput", data);
		
		$('input', this.element).autocomplete(data.url);//using default parameters
		
		return this.element;
	}
  }
);

export const  AutocompleteList = $.extend({}, Input, {

    events: [
        ["autocompletelist.change", "onAutocompleteChange", "input"],
	 ],

	onAutocompleteChange: function(event, value, text) {
		
		if (event.data && event.data.element)
		{
			event.data.element.trigger('propertyChange', [value, this]);
		}
	},

	setValue: function(value) {
		$('input', this.element).data("autocompleteList").setValue(value);
	},

	init: function(data) {
		
		this.element = this.render("textinput", data);
		
		$('input', this.element).autocompleteList(data);//using default parameters
		
		return this.element;
	}
  }
);

